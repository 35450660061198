import axios from 'axios';
const BaseUrl = process.env.REACT_APP_BASEURL;



export const getMenus  = () => {
    const token = localStorage.getItem ('token') || '';
    const url = BaseUrl+'menus';
    return axios({
        method: 'get',
        url ,
        headers: {
             Authorization: "Bearer "+token
        }
      });
      
  }