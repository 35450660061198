import {useEffect, useRef, useState} from 'react' ;
//components
import FormLabel from './FormLabel';
import ErrorMessage from './ErrorMessage';
//components
import Input from './Input'
const GoogleMap = (props) => {
    const {latitude = 10.793591698344498 , longitude = 79.13477994027248, circle, addressError, address = "", circleError } = props;

    useEffect(()=> {
        const googleMapScript= document.createElement('script');
        googleMapScript.src= "https://maps.googleapis.com/maps/api/js?sensor=false&libraries=places&key=AIzaSyDOmj5IUQnHCwXYlkkaeEahGpqZiRWc0iw";
        googleMapScript.async= true ;
        document.body.appendChild(googleMapScript);
        googleMapScript.addEventListener('load', () =>{
            initialize();
        })
    },[true])
    const googleMapRef = useRef();
    const autocompleteRef = useRef();
    const [ circleRef, setCircleRef ] = useState(null);
    const initialize = ()=>{
        const google = window.google;
        let latlng = new google.maps.LatLng(latitude, longitude);
        let map = new google.maps.Map(googleMapRef.current, {
            center : latlng,
            zoom : 15
        });
        let marker = new google.maps.Marker({
            map : map,
            position : latlng,
            draggable : true,
            anchorPoint : new google.maps.Point(0, -29)
        });
        const cityCircle = new google.maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map,
            center: latlng,
            radius: parseInt(circle) || 200,
        });
        let geocoder = new google.maps.Geocoder();
        autocompleteRef.current.value = address;
        let autocomplete = new google.maps.places.Autocomplete(autocompleteRef.current);
        autocomplete.bindTo('bounds', map);
        cityCircle.bindTo('center', marker, 'position');
        setCircleRef(cityCircle);
        let infowindow = new google.maps.InfoWindow();
        autocomplete.addListener('place_changed', function(){
            infowindow.close();
            marker.setVisible(false);
            let place = autocomplete.getPlace();
            if(!place.geometry){
                window.alert("Autocomplete's returned place contains no geometry");
                return;
            }
              
            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);
            }
            marker.setPosition(place.geometry.location);
            marker.setVisible(true);          
        
            bindDataToForm(place.formatted_address, place.geometry.location.lat(),place.geometry.location.lng(), cityCircle.radius);
            infowindow.setContent(place.formatted_address);
            infowindow.open(map, marker);
        })
        // this function will work on marker move event into map 
        google.maps.event.addListener(marker, 'dragend', function(a, b) {
            geocoder.geocode({'latLng': marker.getPosition()}, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    if (results[0]) {   
                        autocompleteRef.current.value = results[0].formatted_address;
                        bindDataToForm(results[0].formatted_address,marker.getPosition().lat(),marker.getPosition().lng(), cityCircle.radius);
                        infowindow.setContent(results[0].formatted_address);
                        infowindow.open(map, marker);
                    }
                }
            });
        });
    }
    function bindDataToForm(address,lat,lng, radius){
        // document.getElementById('location').value = address;
        // document.getElementById('lat').value = lat;
        // document.getElementById('lng').value = lng;
        if(props.changeLatLng){
            props.changeLatLng(lat, lng, address, radius)
        }
     }

     const changeCircle = (event)=> {
        circleRef.setRadius(parseInt(event.target.value) || 10);
        if(props.changeCircle) props.changeCircle(event);
     }
    return(
        <>
        <div className = 'map-autocomplete mb-3 row'>
            <div className = 'col-12 col-md-6'>
                <FormLabel label = 'Address'/>
                <input ref = {autocompleteRef} className = "input-controls" type="text" placeholder="Enter a location" />
                <ErrorMessage label = {addressError}/>
            </div>
            <div className = 'col-md-3'>
                <Input label = 'Circle' onChange = {changeCircle} value = {props.circle}/>
                <ErrorMessage label = {circleError}/>
            </div>
        </div>
        <div ref= {googleMapRef} style= {{width: '100%', height: '250px'}}></div>
        </>
    )
}
export default GoogleMap