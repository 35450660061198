//components
import FormLabel from './FormLabel';
//utils
import { splitPascalCase } from '../../Utils/splitPascalCase' ;
const Radio = (props) =>{
   const{ label, radios, name, onChange, currentValue, disabled = false, mandatory = true } = props;
   const onChangeHandler= (e) => {
       if(onChange){
           onChange(e)
       }
   }
    return(
        <>
            {label && <FormLabel label = {label} mandatory = {mandatory}/>}
                <div>
                    {radios.map(( radio, index )=>{
                        return(
                            <div key = {index} className="form-check form-check-inline">
                                <input type = "radio" className = "form-check-input"  name = {name} onChange = {onChangeHandler} value = {radio.value} checked = {currentValue == radio.value} disabled = {disabled} />
                                        <label className = "form-check-label f-s13">
                                            { radio.label || splitPascalCase(radio.value)  }
                                        </label>
                            </div>
                        ) 
                    })}
                </div>
        </>
    )

}
export default Radio