const progressBar=()=>{
    return(
        <>
            <div className="container f-s14">
                <ul className="progressbar clearfix  p-0">
                    <li className="active">Signup</li>
                    <li>OTP verify</li>
                    <li>Dashboard</li>
                </ul>
            </div>
        </>
    )

} 
export default progressBar