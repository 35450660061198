export default [{
    "offset": "GMT-12:00",
    "name": "Etc/GMT-12 GMT-12:00",
    "label": "Etc/GMT-12",
    "value": "-12:00"
}, {
    "offset": "GMT-11:00",
    "name": "Etc/GMT-11 GMT-11:00",
    "label": "Etc/GMT-11",
    "value": "-11:00"
}, {
    "offset": "GMT-11:00",
    "name": "Pacific/Midway GMT-11:00",
    "label": "Pacific/Midway",
    "value": "-11:00"
}, {
    "offset": "GMT-10:00",
    "name": "America/Adak GMT-10:00",
    "label": "America/Adak",
    "value": "-10:00"
}, {
    "offset": "GMT-09:00",
    "name": "America/Anchorage GMT-09:00",
    "label": "America/Anchorage",
    "value": "-09:00"
}, {
    "offset": "GMT-09:00",
    "name": "Pacific/Gambier GMT-09:00",
    "label": "Pacific/Gambier",
    "value": "-09:00"
}, {
    "offset": "GMT-08:00",
    "name": "America/Dawson_Creek GMT-08:00",
    "label": "America/Dawson_Creek",
    "value": "-08:00"
}, {
    "offset": "GMT-08:00",
    "name": "America/Ensenada GMT-08:00",
    "label": "America/Ensenada",
    "value": "-08:00"
}, {
    "offset": "GMT-08:00",
    "name": "America/Los_Angeles GMT-08:00",
    "label": "America/Los_Angeles",
    "value": "-08:00"
}, {
    "offset": "GMT-07:00",
    "name": "America/Chihuahua GMT-07:00",
    "label": "America/Chihuahua",
    "value": "-07:00"
}, {
    "offset": "GMT-07:00",
    "name": "America/Denver GMT-07:00",
    "label": "America/Denver",
    "value": "-07:00"
}, {
    "offset": "GMT-06:00",
    "name": "America/Belize GMT-06:00",
    "label": "America/Belize",
    "value": "-06:00"
}, {
    "offset": "GMT-06:00",
    "name": "America/Cancun GMT-06:00",
    "label": "America/Cancun",
    "value": "-06:00"
}, {
    "offset": "GMT-06:00",
    "name": "America/Chicago GMT-06:00",
    "label": "America/Chicago",
    "value": "-06:00"
}, {
    "offset": "GMT-06:00",
    "name": "Chile/EasterIsland GMT-06:00",
    "label": "Chile/EasterIsland",
    "value": "-06:00"
}, {
    "offset": "GMT-05:00",
    "name": "America/Bogota GMT-05:00",
    "label": "America/Bogota",
    "value": "-05:00"
}, {
    "offset": "GMT-05:00",
    "name": "America/Havana GMT-05:00",
    "label": "America/Havana",
    "value": "-05:00"
}, {
    "offset": "GMT-05:00",
    "name": "America/New_York GMT-05:00",
    "label": "America/New_York",
    "value": "-05:00"
}, {
    "offset": "GMT-04:30",
    "name": "America/Caracas GMT-04:30",
    "label": "America/Caracas",
    "value": "-04:30"
}, {
    "offset": "GMT-04:00",
    "name": "America/Campo_Grande GMT-04:00",
    "label": "America/Campo_Grande",
    "value": "-04:00"
}, {
    "offset": "GMT-04:00",
    "name": "America/Glace_Bay GMT-04:00",
    "label": "America/Glace_Bay",
    "value": "-04:00"
}, {
    "offset": "GMT-04:00",
    "name": "America/Goose_Bay GMT-04:00",
    "label": "America/Goose_Bay",
    "value": "-04:00"
}, {
    "offset": "GMT-04:00",
    "name": "America/Santiago GMT-04:00",
    "label": "America/Santiago",
    "value": "-04:00"
}, {
    "offset": "GMT-04:00",
    "name": "America/La_Paz GMT-04:00",
    "label": "America/La_Paz",
    "value": "-04:00"
}, {
    "offset": "GMT-03:00",
    "name": "America/Argentina/Buenos_Aires GMT-03:00",
    "label": "America/Argentina/Buenos_Aires",
    "value": "-03:00"
}, {
    "offset": "GMT-03:00",
    "name": "America/Montevideo GMT-03:00",
    "label": "America/Montevideo",
    "value": "-03:00"
}, {
    "offset": "GMT-03:00",
    "name": "America/Araguaina GMT-03:00",
    "label": "America/Araguaina",
    "value": "-03:00"
}, {
    "offset": "GMT-03:00",
    "name": "America/Godthab GMT-03:00",
    "label": "America/Godthab",
    "value": "-03:00"
}, {
    "offset": "GMT-03:00",
    "name": "America/Miquelon GMT-03:00",
    "label": "America/Miquelon",
    "value": "-03:00"
}, {
    "offset": "GMT-03:00",
    "name": "America/Sao_Paulo GMT-03:00",
    "label": "America/Sao_Paulo",
    "value": "-03:00"
}, {
    "offset": "GMT-03:30",
    "name": "America/St_Johns GMT-03:30",
    "label": "America/St_Johns",
    "value": "-03:30"
}, {
    "offset": "GMT-02:00",
    "name": "America/Noronha GMT-02:00",
    "label": "America/Noronha",
    "value": "-02:00"
}, {
    "offset": "GMT-01:00",
    "name": "Atlantic/Cape_Verde GMT-01:00",
    "label": "Atlantic/Cape_Verde",
    "value": "-01:00"
}, {
    "offset": "GMT",
    "name": "Europe/Belfast GMT",
    "label": "Europe/Belfast",
    "value": "00:00"
}, {
    "offset": "GMT",
    "name": "Africa/Abidjan GMT",
    "label": "Africa/Abidjan",
    "value": "00:00"
}, {
    "offset": "GMT",
    "name": "Europe/Dublin GMT",
    "label": "Europe/Dublin",
    "value": "00:00"
}, {
    "offset": "GMT",
    "name": "Europe/Lisbon GMT",
    "label": "Europe/Lisbon",
    "value": "00:00"
}, {
    "offset": "GMT",
    "name": "Europe/London GMT",
    "label": "Europe/London",
    "value": "00:00"
}, {
    "offset": "UTC",
    "name": "UTC UTC",
    "label": "UTC",
    "value": "UTC"
}, {
    "offset": "GMT+01:00",
    "name": "Africa/Algiers GMT+01:00",
    "label": "Africa/Algiers",
    "value": "+01:00"
}, {
    "offset": "GMT+01:00",
    "name": "Africa/Windhoek GMT+01:00",
    "label": "Africa/Windhoek",
    "value": "+01:00"
}, {
    "offset": "GMT+01:00",
    "name": "Atlantic/Azores GMT+01:00",
    "label": "Atlantic/Azores",
    "value": "+01:00"
}, {
    "offset": "GMT+01:00",
    "name": "Atlantic/Stanley GMT+01:00",
    "label": "Atlantic/Stanley",
    "value": "+01:00"
}, {
    "offset": "GMT+01:00",
    "name": "Europe/Amsterdam GMT+01:00",
    "label": "Europe/Amsterdam",
    "value": "+01:00"
}, {
    "offset": "GMT+01:00",
    "name": "Europe/Belgrade GMT+01:00",
    "label": "Europe/Belgrade",
    "value": "+01:00"
}, {
    "offset": "GMT+01:00",
    "name": "Europe/Brussels GMT+01:00",
    "label": "Europe/Brussels",
    "value": "+01:00"
}, {
    "offset": "GMT+02:00",
    "name": "Africa/Cairo GMT+02:00",
    "label": "Africa/Cairo",
    "value": "+02:00"
}, {
    "offset": "GMT+02:00",
    "name": "Africa/Blantyre GMT+02:00",
    "label": "Africa/Blantyre",
    "value": "+02:00"
}, {
    "offset": "GMT+02:00",
    "name": "Asia/Beirut GMT+02:00",
    "label": "Asia/Beirut",
    "value": "+02:00"
}, {
    "offset": "GMT+02:00",
    "name": "Asia/Damascus GMT+02:00",
    "label": "Asia/Damascus",
    "value": "+02:00"
}, {
    "offset": "GMT+02:00",
    "name": "Asia/Gaza GMT+02:00",
    "label": "Asia/Gaza",
    "value": "+02:00"
}, {
    "offset": "GMT+02:00",
    "name": "Asia/Jerusalem GMT+02:00",
    "label": "Asia/Jerusalem",
    "value": "+02:00"
}, {
    "offset": "GMT+03:00",
    "name": "Africa/Addis_Ababa GMT+03:00",
    "label": "Africa/Addis_Ababa",
    "value": "+03:00"
}, {
    "offset": "GMT+03:00",
    "name": "Asia/Riyadh89 GMT+03:00",
    "label": "Asia/Riyadh89",
    "value": "+03:00"
}, {
    "offset": "GMT+03:00",
    "name": "Europe/Minsk GMT+03:00",
    "label": "Europe/Minsk",
    "value": "+03:00"
}, {
    "offset": "GMT+03:30",
    "name": "Asia/Tehran GMT+03:30",
    "label": "Asia/Tehran",
    "value": "+03:30"
}, {
    "offset": "GMT+04:00",
    "name": "Asia/Dubai GMT+04:00",
    "label": "Asia/Dubai",
    "value": "+04:00"
}, {
    "offset": "GMT+04:00",
    "name": "Asia/Yerevan GMT+04:00",
    "label": "Asia/Yerevan",
    "value": "+04:00"
}, {
    "offset": "GMT+04:00",
    "name": "Europe/Moscow GMT+04:00",
    "label": "Europe/Moscow",
    "value": "+04:00"
}, {
    "offset": "GMT+04:30",
    "name": "Asia/Kabul GMT+04:30",
    "label": "Asia/Kabul",
    "value": "+04:30"
}, {
    "offset": "GMT+05:00",
    "name": "Asia/Tashkent GMT+05:00",
    "label": "Asia/Tashkent",
    "value": "+05:00"
}, {
    "offset": "GMT+05:30",
    "name": "Asia/Kolkata GMT+05:30",
    "label": "Asia/Kolkata",
    "value": "+05:30"
}, {
    "offset": "GMT+05:45",
    "name": "Asia/Katmandu GMT+05:45",
    "label": "Asia/Katmandu",
    "value": "+05:45"
}, {
    "offset": "GMT+06:00",
    "name": "Asia/Dhaka GMT+06:00",
    "label": "Asia/Dhaka",
    "value": "+06:00"
}, {
    "offset": "GMT+06:00",
    "name": "Asia/Yekaterinburg GMT+06:00",
    "label": "Asia/Yekaterinburg",
    "value": "+06:00"
}, {
    "offset": "GMT+06:30",
    "name": "Asia/Rangoon GMT+06:30",
    "label": "Asia/Rangoon",
    "value": "+06:30"
}, {
    "offset": "GMT+07:00",
    "name": "Asia/Bangkok GMT+07:00",
    "label": "Asia/Bangkok",
    "value": "+07:00"
}, {
    "offset": "GMT+07:00",
    "name": "Asia/Novosibirsk GMT+07:00",
    "label": "Asia/Novosibirsk",
    "value": "+07:00"
}, {
    "offset": "GMT+08:00",
    "name": "Etc/GMT+8 GMT+08:00",
    "label": "Etc/GMT+8",
    "value": "+08:00"
}, {
    "offset": "GMT+08:00",
    "name": "Asia/Hong_Kong GMT+08:00",
    "label": "Asia/Hong_Kong",
    "value": "+08:00"
}, {
    "offset": "GMT+08:00",
    "name": "Asia/Krasnoyarsk GMT+08:00",
    "label": "Asia/Krasnoyarsk",
    "value": "+08:00"
}, {
    "offset": "GMT+08:00",
    "name": "Australia/Perth GMT+08:00",
    "label": "Australia/Perth",
    "value": "+08:00"
}, {
    "offset": "GMT+08:45",
    "name": "Australia/Eucla GMT+08:45",
    "label": "Australia/Eucla",
    "value": "+08:45"
}, {
    "offset": "GMT+09:00",
    "name": "Asia/Irkutsk GMT+09:00",
    "label": "Asia/Irkutsk",
    "value": "+09:00"
}, {
    "offset": "GMT+09:00",
    "name": "Asia/Seoul GMT+09:00",
    "label": "Asia/Seoul",
    "value": "+09:00"
}, {
    "offset": "GMT+09:00",
    "name": "Asia/Tokyo GMT+09:00",
    "label": "Asia/Tokyo",
    "value": "+09:00"
}, {
    "offset": "GMT+09:30",
    "name": "Australia/Adelaide GMT+09:30",
    "label": "Australia/Adelaide",
    "value": "+09:30"
}, {
    "offset": "GMT+09:30",
    "name": "Australia/Darwin GMT+09:30",
    "label": "Australia/Darwin",
    "value": "+09:30"
}, {
    "offset": "GMT+09:30",
    "name": "Pacific/Marquesas GMT+09:30",
    "label": "Pacific/Marquesas",
    "value": "+09:30"
}, {
    "offset": "GMT+10:00",
    "name": "Etc/GMT+10 GMT+10:00",
    "label": "Etc/GMT+10",
    "value": "+10:00"
}, {
    "offset": "GMT+10:00",
    "name": "Australia/Brisbane GMT+10:00",
    "label": "Australia/Brisbane",
    "value": "+10:00"
}, {
    "offset": "GMT+10:00",
    "name": "Australia/Hobart GMT+10:00",
    "label": "Australia/Hobart",
    "value": "+10:00"
}, {
    "offset": "GMT+10:00",
    "name": "Asia/Yakutsk GMT+10:00",
    "label": "Asia/Yakutsk",
    "value": "+10:00"
}, {
    "offset": "GMT+10:30",
    "name": "Australia/Lord_Howe GMT+10:30",
    "label": "Australia/Lord_Howe",
    "value": "+10:30"
}, {
    "offset": "GMT+11:00",
    "name": "Asia/Vladivostok GMT+11:00",
    "label": "Asia/Vladivostok",
    "value": "+11:00"
}, {
    "offset": "GMT+11:30",
    "name": "Pacific/Norfolk GMT+11:30",
    "label": "Pacific/Norfolk",
    "value": "+11:30"
}, {
    "offset": "GMT+12:00",
    "name": "Etc/GMT+12 GMT+12:00",
    "label": "Etc/GMT+12",
    "value": "+12:00"
}, {
    "offset": "GMT+12:00",
    "name": "Asia/Anadyr GMT+12:00",
    "label": "Asia/Anadyr",
    "value": "+12:00"
}, {
    "offset": "GMT+12:00",
    "name": "Asia/Magadan GMT+12:00",
    "label": "Asia/Magadan",
    "value": "+12:00"
}, {
    "offset": "GMT+12:00",
    "name": "Pacific/Auckland GMT+12:00",
    "label": "Pacific/Auckland",
    "value": "+12:00"
}, {
    "offset": "GMT+12:45",
    "name": "Pacific/Chatham GMT+12:45",
    "label": "Pacific/Chatham",
    "value": "+12:45"
}, {
    "offset": "GMT+13:00",
    "name": "Pacific/Tongatapu GMT+13:00",
    "label": "Pacific/Tongatapu",
    "value": "+13:00"
}, {
    "offset": "GMT+14:00",
    "name": "Pacific/Kiritimati GMT+14:00",
    "label": "Pacific/Kiritimati",
    "value": "+14:00"
}]