import { useState, useEffect } from 'react' ;
//import {useRouter} from 'next/router' ;
//componetns
import Layout from '../Components/Layout' ;
import ShowDetails from '../Components/Common/ShowDetails' ;
import ShowImage from '../Components/Common/ShowImage' ;
import PageHeader from '../Components/Common/PageHeader' ;
import Form from '../Components/Common/Form' ;
import Tap from '../Components/Common/Tap' ;
import Button from '../Components/Common/Button' ;
import Select from '../Components/Common/Select';
import ErrorMessage from '../Components/Common/ErrorMessage';
//utils
import { isUserNameValid as getIsUserNameValid} from '../Utils/isUserNameValid';
import {splitPascalCase} from '../Utils/splitPascalCase' ;
import {isPasswordValid} from '../Utils/IsPasswordValid' ;
import { loginError } from '../constant/errorMessage';
import timezoneList from '../Utils/timezone';
//services
import { getUserDetails, updatePassword, updateTimezone, updateUsername } from '../services/settings';
import Input from '../Components/Common/Input';
import { userNameValidate } from '../services/auth';

const Row = (props) => {
    return(
    <div className = 'mt-3'>
        {props.children}
    </div>
    )
}

const Setting = (props) => {

    //const router = useRouter();
    // state for change tap
    const [activeTap, setActiveTap] = useState('companyInfo')
    // state for change password
    const [passwords, setPasswords] = useState({})
    // state for error message password
    const [passwordError, setPasswordError] = useState({})

    const [ userDetail, setUserDetail] = useState({customCompanyName:''})

    const [isLoader, setLoader] = useState(false);
    const [isLoaderCompanyName, setLoaderCompanyName] = useState(false);
    const [companyIdValid, setCompanyIdValid] = useState(false);
    

    const [ updatePasswordRequest, setUpdatePasswordRequest ] = useState(0);

    const [ updateTimezoneStatus, setupdateTimezoneStatus ] = useState(0);
    const [ updateCompanyName, setUpdateCompanyName ] = useState(0);

    const [ errorCustomCompanyId, setErrorCustomCompanyId ] = useState('');

    const passwordChangeHandler = (event, key) => {
        setPasswords({...passwords, [key] : event.target.value})
        setPasswordError({...passwordError, [key] : ''})
    }

    useEffect ( ()=>{
        //setLoader(true);
        getUserDetails().then ( ( results ) => {
            const response = results.data;
            const data = {
                name: `${response.first_name} ${response.last_name}`,
                timezone: response.timezone,
                mailId: response.mail_id,
                companyId: response.company_id,
                customName: response.custom_name,
                customCompanyName:''
            }
            setUserDetail(data);
        }).catch((err)=>{
            //setLoader(false);
            console.log(err)
        });
    },[true]);

    const savePassword = () => {
        
        const errorMessage = {}

        if( passwordFields.length){

            // flag for after check all input fields not empty to continue to check new and confirm password match
            let matchNewConfirmPassword = true ;
            // flag for after check all input fields not empty and check new and confirm password match to continue to check new and confirm password match password requirement
            let validPassword = true ;

            passwordFields.map((fields) => {
                fields.row.map((col) => {
                    // empty check
                    if(!passwords[col.key]){
                        errorMessage[col.key] = splitPascalCase(col.key) + ' field should be empty' ;
                        matchNewConfirmPassword = false ;
                        validPassword = false ;
                    }
                    // new and confirm password check
                    else if (matchNewConfirmPassword && passwords.newPassword != passwords.confirmPassword){
                        validPassword = false ;
                        errorMessage.confirmPassword = 'new and confirm password not match';
                    }
                    // to check password match password requirement
                    else if ( validPassword && col.validate == 'password' && !isPasswordValid(passwords[col.key])){
                        errorMessage.confirmPassword = loginError.password;
                        validPassword = false;
                    }
                })
            })

            if(validPassword && matchNewConfirmPassword) {
                const data = {
                    old_password: passwords.currentPassword,
                    new_password: passwords.newPassword
                }
                setLoader(true);
                updatePassword(data).then( ( results ) => {
                    setUpdatePasswordRequest(1);
                    setLoader(false);
                }).catch(()=>{
                    setUpdatePasswordRequest(2);
                    setLoader(false);
                });
            }
            else {
                setPasswordError({...errorMessage})
            }

            
        }
    }
    const value = {
        organizationName: 'Delta',
        firstName : 'siva',
        lastName : 'seelan',
        email : 'thambisivajaya007@gmail.com',
        employeeId : 'sivasee',
        mobileNumber: 9993731362,
        timeZone : 'GMT +5.30',
        country : 'india'

    }
    const basicInformation = {
        col1 : [   
            {
                row : [{ key : 'name'}]
            },
            {
                row : [{ key : 'mailId'}]
            },
            {
                row : [{ key : 'companyId'}]
            }
        ]

    }
    const passwordFields = [
               
            {
                row : [{ type : 'password', key : 'currentPassword', validate : 'password'}]
            },
            {
                row : [{ type : 'password', key : 'newPassword', validate : 'password'}]
            },
            {
                row : [{ type : 'password', key : 'confirmPassword', validate : 'password'}]
            }
        
    ]
    const subscriptionPlans = [
        {planName : 'Basic', planPrice : '$300', subText : 'monthly', planFeature : ['fdewq', 'wdwfwff'], activated : 'Currenly Activated'},
        {planName : 'Middle', planPrice : '$300', subText : 'monthly', planFeature : ['fdewq', 'wdwfwff'], button : 'Upgrad Plan'},
        {planName : 'Primium', planPrice : '$300', subText : 'monthly', planFeature : ['fdewq', 'wdwfwff','wdwfwff','wdwfwff'], button : 'Upgrad Plan'}
    ]
    
    const changeTap = (tabName) => {
        setActiveTap(tabName)
    }

    const onChangeTimezone = (e) => {
        const updateUser = {...userDetail};
        updateUser.timezone = e.target.value;
        setUserDetail(updateUser)
    }

    const updateTimezoneHandler =async () => {
        try {
            setLoader(true);
            const updatedResult = await updateTimezone({timezone: userDetail.timezone});
            if(updatedResult.data.status) {
                setLoader(false);
                setupdateTimezoneStatus(1)
            }
            else throw new Error("something went wrong! try again")
        }
        catch(error) {
            setLoader(false);
            setupdateTimezoneStatus(2)
            console.log(error);
        }
    }

   

    const tapMenu = [{key: 'companyInfo', label:"User Info", icon: 'bi bi-person-circle'}, {key: 'password', icon: 'bi bi-key-fill'}]

    const onChangeCustomId = (e) =>{
        const updateUser = {...userDetail};
        updateUser.customCompanyName = e.target.value;
        setUserDetail(updateUser);
    }

    useEffect(() => {
        const timeOutId = setTimeout( validateOrganizationId, 600);
        return () => clearTimeout(timeOutId);
    }, [userDetail.customCompanyName]);


    const validateOrganizationId = () => {
        const isUserNameValid = getIsUserNameValid(userDetail.customCompanyName)
        const userNameValidLength = userDetail.customCompanyName.length > 5;
        if(userDetail.customCompanyName && isUserNameValid && userNameValidLength){
            userNameValidate(userDetail.customCompanyName).then((result)=>{
                setErrorCustomCompanyId('');   
                setCompanyIdValid(true);             
            }).catch(()=>{
                setCompanyIdValid(false);
                setErrorCustomCompanyId('Custom Company ID already exits');
            })
        }
        else if(userDetail.customCompanyName){
            setCompanyIdValid(false);
            const errrMessage = !isUserNameValid ? 'Custom Company ID not correct format, It should be numbers, lowercase and  _  only allowed' : !userNameValidLength ? "Custom Company ID length should be more than 5" : "";
            setErrorCustomCompanyId(errrMessage);
        }
        else {
            setCompanyIdValid(false);
            setErrorCustomCompanyId('');
        }
    }

    const addCustomCompanyId =async () =>{
        try {
            setLoaderCompanyName(true);
            const updatedResult = await updateUsername({user_name: userDetail.customCompanyName});
            if(updatedResult.data.status) {
                setLoaderCompanyName(false);
                setUpdateCompanyName(1);
                const updateUser = {...userDetail};
                updateUser.customName = userDetail.customCompanyName;
                setUserDetail(updateUser)
            }
            else throw new Error("something went wrong! try again")
        }
        catch(error) {
            setLoaderCompanyName(false);
            setUpdateCompanyName(2)
            console.log(error);
        }
    }

    return (
        <Layout>
            <PageHeader title = 'Setting'/>
            <Tap changeTap = {changeTap} tapMenu = {tapMenu} activeTap = {activeTap}>
                <div className = 'mt-2 mb-4'>
                    {activeTap == 'companyInfo' &&
                        <div className = 'px-2'>
                            {/* <ShowImage src = 'profilePic.png' alt = 'prfilePic'/> */}
                            <div className = 'd-md-flex mt-3 pt-3'>
                                <div className = 'col-md-6'>
                                    <ShowDetails col = {basicInformation.col1} value = {userDetail}/>
                                </div>
                            </div>
                            {<div className = 'col-md-6 row'>
                                <div className = 'col-md-6'>
                                    <Input type="text" mandatory={false} className ={ companyIdValid ? 'valid-input' : '' } label="Custom Company ID" value={userDetail.customName ? userDetail.customName : userDetail.customCompanyName} 
                                    helpText={!userDetail.customName ? 'numbers, lowercase and  _  only allowed' : '' }
                                    onChange={onChangeCustomId} readOnly={userDetail.customName ? true : false}
                                    />
                                    <ErrorMessage label = { errorCustomCompanyId }/>
                                </div>
                                {!userDetail.customName && <div className = 'col-md-6'>
                                    <Button className = 'btn-primary btn-sm mt-30' onClick = {addCustomCompanyId} disabled={isLoaderCompanyName || errorCustomCompanyId || !companyIdValid}><i className = 'bi bi-pencil-fill mr-5'></i>{isLoaderCompanyName ? "Loading..." : "Add Company ID"}</Button>
                                </div>}
                                {updateCompanyName ? updateCompanyName === 1  ? <div className="alert alert-success mt-10" role="alert">Custom Company Id added successfully!</div> : 
                                <div className="alert alert-danger mt-10" role="alert">Custom Company Id has not been added, Please try again</div> : null}
                            </div>}
                            <div className = 'col-md-6 row'>
                                <div className = 'col-md-6'>
                                    <Select mandatory={false} keyValue={true} label="Timezone" value={userDetail.timezone} onChange={onChangeTimezone} options = {timezoneList}/>
                                </div>
                                <div className = 'col-md-6'>
                                    <Button className = 'btn-primary btn-sm mt-30' onClick = {updateTimezoneHandler} disabled={isLoader}><i className = 'bi bi-pencil-fill mr-5'></i>{isLoader ? "Loading..." : "Update timezone"}</Button>
                                </div>
                                {updateTimezoneStatus ? updateTimezoneStatus === 1  ? <div className="alert alert-success mt-10" role="alert">Timezone updated successfully!</div> : 
                                <div className="alert alert-danger mt-10" role="alert">Timezone not updated, Please try again</div> : null}
                            </div>
                        </div>
                    }
                    {activeTap == 'password' && 
                        <div className = 'd-md-flex justify-content-center'>
                            <div className = 'col-md-4'>
                                <h6 className = 'mb-2'>Change Password</h6>
                                <Form className = 'col' rows = {passwordFields} value = {passwords} errorMessage = {passwordError} onChange = {passwordChangeHandler}/>
                                <Button className = 'btn-primary w-100 f-s14' onClick = {savePassword} disabled={isLoader}>{isLoader ? "Loading..." : "Save"}</Button>
                                {updatePasswordRequest ? updatePasswordRequest === 1  ? <div className="alert alert-success mt-10" role="alert">Password updated successfully!</div> : 
                                <div className="alert alert-danger mt-10" role="alert">Invalid Password, Please try with right one</div> : null}
                            </div>
                        </div>
                    }
                    { activeTap == 'subscription' &&
                        <div className = 'd-md-flex align-items-stretch'>
                            {subscriptionPlans.map((plan, index) => {
                                return(
                                <div key = {index} className = 'col px-3 mt-3 mt-md-0'>
                                    <div className = "card h-100">
                                        <div className = "card-body d-flex flex-column justify-content-between">
                                            <div>
                                                <div className = 'text-center'>
                                                    <div className = 'f-w-600 f-s16 text-uppercase'>{plan.planName}</div>
                                                    <h3 className = 'f-w-bold m-0 mt-2'>{plan.planPrice}</h3>
                                                    <div className = 'text-secondary f-s14'>{plan.subText}</div>
                                                </div>
                                                <div className = 'border-top mt-2 mb-4'>
                                                    <div className = 'mt-3'>
                                                        {plan.planFeature.map((featureList, listIndex) => {
                                                            const className = plan.activated ? 'text-success' : 'text-primary' ;
                                                                return(
                                                                    <div className = 'mt-1 f-s16' key = {listIndex}>
                                                                        <span className = {'bi bi-check-circle-fill mr-5 '+className }></span><span className = 'f-w-600'>{featureList}</span>
                                                                    </div>
                                                                )
                                                            })}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className = 'd-flex align-items-stretch'>
                                                {plan.button && <Button className = 'btn-primary w-100 pt-1 pb-1 f-s16'>
                                                    {plan.button}
                                                </Button> }
                                            
                                                {plan.activated && <div className = ' rounded text-center w-100 bg-success text-white w-100 pt-1 pb-1 f-s16'>{plan.activated}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            })}

                        </div>
                    }
                </div>
            </Tap>

        </Layout>
    )
}
export default Setting