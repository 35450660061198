import React, {useState, useRef, useEffect, createContext} from 'react';
import {
    Switch,
    Route,
    Redirect,
    BrowserRouter as Router,
    useHistory,
    useRouteMatch
  } from "react-router-dom";
import { adminNavItems } from '../constant/navItems' ;
import { getMenus } from '../services/menu.js';
import { logout } from '../Utils/login';
import Dashboard from '../Pages/Dashboard';
import { menuItemsContext } from "../context"


const Logged = () => {

    const history = useHistory();

    
    const [ menuList, setMenuList ] = useState( [] );

    let { path, url } = useRouteMatch();




    useEffect(()=>{
        getMenus().then((result)=>{
            const menu = result.data && result.data.menu ?  result.data.menu : []
            localStorage.setItem("role",  result.data && result.data.role ?  result.data.role : "");
            setMenuList(menu)
        }).catch((error)=>{
            if(error && error.response && error.response.status === 401) {
                logout()
                history.push('/login');
            }
            console.log({error})
        })
    },[]);

    const renderRouter = () => {
        return <menuItemsContext.Provider value={menuList}>
                <Switch>
                    {adminNavItems.map((page)=>{
                        if(page.name === "dashboard") {
                            return  <Route name={page.name} component={page.component} menuList={menuList} path={page.router} />
                        }
                        else {
                            return  <Route component={page.component} menuList={menuList} path={page.router} />
                        }
                    })}
                <Redirect exact from="/" to="dashboard" />
                </Switch>
            </menuItemsContext.Provider>
    }


   return <React.Fragment>{renderRouter()}</React.Fragment>
}

export default Logged;