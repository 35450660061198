import React, { useEffect, useState} from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
  useHistory
} from "react-router-dom";
import { adminNavItems } from '../constant/navItems' ;
import PrivateRoute from "./PrivateRouter";
import PublicRoute from "./PublicRouter";
import Login from '../Pages/Login';
import Signup from '../Pages/Signup';
import { isLogin, logout } from '../Utils/login';
import { getMenus } from '../services/menu.js';
import SignupOtpVerify from '../Pages/SignupOtpVerify';
import Logged from '../Components/Logged';


export default () => {

  return (    
    <>  
      <Switch>
          <PublicRoute component={Signup} path="/signup" restricted={true} />
          <PublicRoute component={Login} path="/login" restricted={true} />
          <PublicRoute component={SignupOtpVerify} path="/SignupOtpVerify"  restricted={true} />
          <Route component={Logged} path="/" />
          {/* <PrivateRoute component={Dashboard} path="/dashboard" exact /> */}
          {/* {adminNavItems.map((page)=>{
            return <PrivateRoute path={page.router} loginUser={loginUser} menuList={menuList} component={page.component}/>
          })} */}
          {/* <Route render={props => (
            console.log(props) &&
                isLogin() ?
                  <Redirect to="/attendance" />
                : <Redirect to="/login" />
            )} /> */}
      </Switch>
    </>
  );
}