import Button from '../Components/Common/Button';
import { useEffect, useState } from "react";
import Layout from '../Components/Layout';
import { getCheckInDetail, updateCheckin } from '../services/attendance';
import moment from 'moment';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import PageHeader from '../Components/Common/PageHeader';

const DashBoard = (props) => {

    const [checkInDetail, setCheckInDetail] = useState({});
    const [totalDurationInMinutes, setDuration] = useState(0);

    const [checkinList, setCheckList] = useState([]);



    useEffect(()=>{
        getCheckInDetail().then((results)=>{
            setCheckInDetail(results.data);
            let startTime = "";
            const splitCheckin = [];
            const { entryData } = results.data;
            let totalMinutes = 0;
            if(entryData  && entryData.length) {
                entryData.forEach( (data, index ) => {
                    if(data.checkin_type ===1) {
                        startTime = data.entry_at;
                    }
                    else {                      
                        splitCheckin.push({ checkin: moment(startTime).format("HH:mm"), checkout: moment(data.entry_at).format("HH:mm")});
                        const duration =  moment.duration(moment(data.entry_at, "YYYY-MM-DD HH:mm:ss").diff(moment(startTime, "YYYY-MM-DD HH:mm:ss")));
                        totalMinutes += (duration._data.seconds/60) + duration._data.minutes + (duration._data.hours*60);
                        console.log(totalMinutes)
                        startTime = "";
                    }
                });
                if(startTime) {
                    const duration =  moment.duration(moment(new Date(),"YYYY-MM-DD HH:mm:ss" ).diff(moment(startTime, "YYYY-MM-DD HH:mm:ss")));
                    console.log(duration)
                    splitCheckin.push({ checkin: moment(startTime).format("HH:mm") });
                    totalMinutes += (duration._data.seconds/60) + duration._data.minutes + (duration._data.hours*60);
                    startTime = "";
                }
            }
            setCheckList(splitCheckin);
            setDuration(totalMinutes);
        }).catch()
    }, [true])

    console.log(checkInDetail);

    const onCheckInHandler = () => {
        updateCheckin().then((results)=>{
            const {checkInType, entryData = []} = results.data;
            const checkInDetailObj = {...checkInDetail};
            checkInDetailObj.checkInType = checkInType;
            checkInDetailObj.entryData = entryData.length ? entryData : checkInDetailObj.entryData
            setCheckInDetail(checkInDetailObj)
        }).catch()
    }

    const renderTotals = () => {
        const hours = Math.floor(totalDurationInMinutes/60);
        const minutes = totalDurationInMinutes > 60 ? Math.floor(totalDurationInMinutes%60) : Math.floor(totalDurationInMinutes);
        return ("0" + hours).slice(-2)+":"+("0" + minutes).slice(-2);
    }

    const UrgeWithPleasureComponent = () => (
        <CountdownCircleTimer
          isPlaying
          duration={1}
          colors={['#004777', '#F7B801', '#A30000', '#A30000']}
          colorsTime={[7, 5, 2, 0]}
          onComplete={() => {
            // do your stuff here
            return { shouldRepeat: true, delay: 0 } // repeat animation in 1.5 seconds
          }}
        >
          {() => { return "You are checked in"}}
        </CountdownCircleTimer>
      )

    return (
        <>
            <Layout>
                {/* <h1>Checkin</h1> */}
                <PageHeader title = 'check-in/check-out'/>
                <div style={{textAlign:"center"}}>
                    {checkInDetail.checkInType !== 1 ? <div style={{position:"relative", left:"50%", marginLeft:"-90px", marginBottom:"50px"}}>{UrgeWithPleasureComponent()}</div> : null}
                    {checkInDetail.checkInType !== 1 ? <p style={{  marginBottom:"50px"}}>Total hours - {renderTotals()}</p> : null}
                    <Button className = "btn-primary" onClick = {onCheckInHandler}>
                    {checkInDetail.checkInType === 1 ? "CheckIn" : "CheckOut"}
                    </Button>
                </div>
                {/* <ul>
                    {checkinList.map((ckl)=> <li>{ckl.checkin + ' - ' +(ckl.checkout ? ckl.checkout : "")}</li> )}
                </ul> */}
                
            </Layout>
        </>
    )
}

export default DashBoard