import { Calendar, momentLocalizer } from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from "moment";
import { useState } from "react";
//Components
import Button from "./Button";
//constant
import {monthList} from '../../constant/monthList';

//https://github.com/jquense/react-big-calendar
const BigCalendar = (props)=>{

    const localizer = momentLocalizer(moment);
    const [date, setDate] = useState(new Date());

    const goPreviousMonth = ()=>{
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() - 1);
        newDate.setDate(1);
        setDate(newDate);
    };

    const goNextMonth = ()=>{
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + 1);
        newDate.setDate(1);
        setDate(newDate);
    };

    const goToday = ()=>{
        const today = new Date();
        setDate(today); 
    };

    const onSelectEvent = (e)=>{
        if(props.onSelectEvent) props.onSelectEvent(e);
    };

    const onSelectSlot = (selectDate)=>{
        if(props.onSelectSlot) props.onSelectSlot(selectDate);
    };

    const onDrillDown = (e)=>{
        if(props.onDrillDown) props.onDrillDown(e)
    };

    const ShowYearMonth = monthList[date.getMonth()] + '-' + date.getFullYear();

    const {selectable = false, views = ['month'], events = [], popup = false } = props;

    return(
        <>
        <div className = 'mb-2 d-flex'>
            <div className = 'col'>
                <Button className = 'btn-outline-primary btn-sm mr-5' onClick = {goToday}>Today</Button>
                <Button className = 'btn-primary btn-sm mr-5' onClick = {goPreviousMonth} title = 'Previous month'><i className="bi bi-caret-left-fill"></i></Button>
                <Button className = 'btn-primary btn-sm' onClick = {goNextMonth} title = 'Next month'><i className="bi bi-caret-right-fill"></i></Button>
            </div>
            <div className = 'col'>
                <span className = 'ml-10 f-w-600 f-s18'>{ShowYearMonth}</span>
            </div>
        </div>

            <Calendar
                date = {date}
                style = {{height : 500}}
                selectable = {selectable}
                localizer = {localizer} 
                events = {events}
                onSelectEvent = {onSelectEvent}
                onSelectSlot = {onSelectSlot}
                onDrillDown = {onDrillDown}
                views={views}
                toolbar = {false}
                popup = {popup}
            />

        </>
    )
}
export default BigCalendar;





// //components
// import Select from "./Select";
// import Input from "./Input";
// import Button from "./Button";
// //constant
// import {monthList} from '../../constant/monthList';
// import { useEffect, useRef, useState } from "react";
// const YearMonthPicker = (props)=>{
//     const currentYear = new Date().getFullYear();
//     const [year, setYear] = useState(currentYear);
//     const [isShowPicker, setIsShowPicker] = useState(false);
//     const getYearMonth = (newYear, newMonth)=>{
//         if(props.getYearMonth){
//             props.getYearMonth(newYear, newMonth);
//             setIsShowPicker(false);
//         };
//     };
//     const onFocusHandler = ()=>{
//         setIsShowPicker(true);
//     };
//     const container = useRef();
//     const showHidePicker = (e)=>{
//         if(container.current && !container.current.contains(e.target)){
//             setIsShowPicker(false);
//         }
//     };
//     useEffect(()=>{
//         document.addEventListener('mousedown', showHidePicker);
//         return document.removeEventListener('mousedown', showHidePicker);
//     })
//     return(
//     <div ref = {container}>
//         <div className = 'd-flex'>
//             <div className = 'col-3'>
//                 <Input className = 'position-relative' onFocus = {onFocusHandler}/>
//             </div>
//         </div>
//         {isShowPicker &&
//             <div className = 'dropdown-menu px-1 d-block'>
//                 <div className = 'd-flex align-items-center justify-content-between'>
//                     <Button onClick = {()=> setYear(year-1)}><i className="bi bi-arrow-left-circle large-icon"></i></Button>
//                     <div>{year}</div>
//                     <Button onClick = {()=> setYear(year+1)}><i className="bi bi-arrow-right-circle large-icon"></i></Button>
//                 </div>
//                 <div className = 'row'>
//                     {monthList.map((month, index)=>{
//                         return(
//                             <>
//                                 <div key = {index} className = 'col-3 text-center'>
//                                     <Button onClick = {()=> getYearMonth(year, month.value)} className = 'btn-light w-100 mt-1 mb-1'>
//                                         {month.abbreviation}
//                                     </Button>
//                                 </div>
//                             </>
//                         )
//                     })}
//                 </div>
//             </div>        
//         }

//     </div>
//     )

// };

// const Calendar = ()=>{
//     const date = new Date();
//     const [yearMonth, setYearMonth] = useState({year : date.getFullYear(), month : date.getMonth()});
//     const firstDay = new Date(yearMonth.year, yearMonth.month).getDay();
//     const daysInMonth = 32 - new Date(yearMonth.year, yearMonth.month, 32).getDate();
//     console.log(firstDay, daysInMonth)
//     const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
//     const rows = [];
//     let day = 1;
//     for(let i = 0; i < 6; i++){
//         const col = [];
//         for(var j = 0; j < days.length; j++){
//             const time = new Date();
//             if( day > daysInMonth){
//                 col.push(<div className = 'col' key = {time.getTime()}></div>);
//             }
//             else if(firstDay > j && i == 0){
//                 col.push(<div className = 'col' key = {time.getTime()}></div>); 
//             }
//             else{
//                 col.push(<div className = 'col' key = {time.getTime()}>{day}</div>);
//                 day = day + 1;            
//             }
//         };
//         rows.push(
//             <div className = 'd-flex justify-content-between text-center' key = {i}>
//                 {col}
//             </div>
//         )
//     };
//     console.log(rows)
//     const getYearMonth = (newYear, newMonth)=>{
//         setYearMonth({year : newYear, month : newMonth-1});
//     };
//     console.log(yearMonth)
//     return(
//         <>
//             <YearMonthPicker getYearMonth = {getYearMonth}/>
//             <div className = 'pt-1 pb-1 border f-s14 d-flex justify-content-between'>
//                 {days.map((day, index)=>{
//                     return(
//                         <div key = {index} className = 'text-center col'>{day}</div>
//                     )
//                 })}
//             </div>
//             {rows}
//         </>
//     )
// };
// export default Calendar;