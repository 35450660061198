import { useState, useEffect, useRef} from 'react';
//components
import Input from './Input';
import Button from './Button';
import FormLabel from './FormLabel';
// utils 
import {isValidNumber} from '../../Utils/isValidNumber';
const TimePicker = (props)=>{

    const [state, setState] = useState({ });

    const convert24Hours = (newHours, newMinute, newTimeFormat)=>{
        let hours = newHours;
        let minute = newMinute;
        let timeFormat = newTimeFormat;
        if(timeFormat == 'PM'){
            hours = parseInt(hours);
            hours = hours + 12;
        }
        hours = ('0' + hours).slice(-2);
        minute = ('0' + minute).slice(-2);
        return hours+ ':' + minute + ':' + '00';
    };

    const onClickHandler = ( handler, upDown, ) =>{
        let hours = state.hours; 
        let minute = state.minute;
        let timeFormat = state.timeFormat;
        //hours handler 
        if(handler == 'hours'){
            hours = !state.hours ? 0 : hours;
            hours = parseInt(hours);
            if(upDown == 'up'){
                hours = hours <= 1 ? 12 : hours - 1;
            }
            else{
                hours = hours >= 12 ? 1 : hours + 1;
            }
            hours = ('0' + hours).slice(-2);
        }
        //minute handler 
        else if(handler == 'minute'){
            minute = !state.minute ? 0 : minute;
            minute = parseInt(minute);
            if(upDown == 'up'){
                minute = minute <= 0 ? 59 : minute - 1;
            }
            else{
                minute = minute >= 59 ? 0 : minute + 1;
            }
            minute = ('0' + minute).slice(-2);
        }
        else if(handler == 'timeFormat'){
            timeFormat = timeFormat == 'AM' ? 'PM' : 'AM';
        }
        if(props.onClick) props.onClick(convert24Hours(hours, minute, timeFormat));
        setState({hours, minute, timeFormat});
    };

    const onChangeHandler =(event, handler)=>{
        let hours = state.hours;
        let minute = state.minute;
        let timeFormat = state.timeFormat;
        let value = event.target.value;
        if(isValidNumber(value)){
            if(handler == 'hours'){
                hours = value > 12 ? 12 : value;
                hours = value < 0 ? 0 : hours;
            }
            else if(handler == 'minute'){
                minute = value > 59 ? 59 : value;
                minute = value < 0 ? 0 : minute;
            }
            if(props.onChange) props.onChange(convert24Hours(hours, minute, timeFormat));
            setState({...state, hours, minute});
        }
    };

    const saveTime = ()=>{
        let hours = state.hours;
        let minute = state.minute;
        let timeFormat = state.timeFormat;
        if(!hours || !minute || hours == 0){
            hours = 12;
            minute = 0;
            timeFormat = 'AM'
        }
        hours = ('0' + hours).slice(-2);
        minute = ('0' + minute).slice(-2);
        if(props.saveTime) props.saveTime(convert24Hours(hours, minute, timeFormat));
        setState({hours, minute, timeFormat});
        setShow(false);
    };
    
    const[show,setShow]=useState(false) ;
    
    const showElement = () =>{
        setShow (true);
    } ;

    // outside click close
    const conatiner = useRef();
    const handleClick = (e)=> {
        console.log(state);
        if(conatiner.current.contains(e.target)){

            console.log(false)
            
        }
        else{
            if(!state.hours || !state.minute || state.hours == 0){
                setState({hours : '12', minute : '00', timeFormat : 'AM'});
                props.saveTime(convert24Hours(12, 0, 'AM'));
                console.log(true)
            }
            setShow(false);
        }
    };
    useEffect(()=>{
            document.addEventListener('mousedown', handleClick) ;
            return ()=> {
                document.removeEventListener('mousedown', handleClick);
            }
    });
    // -*- ouside click close -*-

    let userViewTime = ( typeof state.hours !== 'undefined' ?  state.hours  + "  :  " +  state.minute  + "  :  " + state.timeFormat : '' );

    const upButtons = [
        {onClick : ()=> onClickHandler('hours', 'up')},
        {onClick : ()=> onClickHandler('minute', 'up')},
        {onClick : ()=> onClickHandler('timeFormat', 'up')}
    ];

    const downButtons = [
        {onClick : ()=> onClickHandler('hours', 'down')},
        {onClick : ()=> onClickHandler('minute', 'down')},
        {onClick : ()=> onClickHandler('timeFormat', 'down')}
    ];

    const inputFields = [
        {onChange : (e)=>onChangeHandler(e, 'hours'), value : state.hours },
        {onChange : (e)=>onChangeHandler(e, 'minute'), value : state.minute},
        {onChange : (e)=>onChangeHandler(e, 'timeFormat'), value : state.timeFormat},

    ];

    const {label, mandatory = true} = props ;
    
    return(
        <>  
            <div className="dropdown">
                <div>
                {label && <FormLabel label = {label} mandatory = {mandatory}/>}
                <div ref = {conatiner}>
                    <Input value = {userViewTime} onFocus = {showElement} readOnly = {true}/>
                    { show && 
                        <div className= {" dropdown-menu px-2 d-block " }>
                            <div>
                                <div className = 'd-flex justify-content-between'>
                                    {upButtons.map((button, index)=>{
                                        return(
                                        <div key = {index} className = 'timePicker mx-1'>
                                            <Button onClick = {button.onClick} className = " btn-outline-primary border-0 w-100 btn-sm">
                                                <i className="bi bi-caret-up-fill "></i>
                                            </Button>
                                        </div>
                                        )
                                    })}
                                </div>
                                <div className = 'd-flex justify-content-between '>
                                    {inputFields.map((input, index)=>{
                                        return(
                                            <div key = {index} className = 'timePicker mt-1 mb-1 mx-1'>
                                                <Input type = "text" value = {input.value || ''} onChange = {input.onChange} maxLength = {2} className = "text-center form-control-sm " />
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className = 'd-flex justify-content-between'>
                                    {downButtons.map((button, index)=>{
                                        return(
                                            <div key = {index} className = 'timePicker mx-1'>
                                                <Button onClick = {button.onClick} className = " btn-outline-primary border-0 w-100 btn-sm">
                                                    <i className="bi bi-caret-down-fill"></i>
                                                </Button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className= "d-flex flex-row-reverse pt-1">
                                <Button className = "btn-outline-primary f-w-bold border-0 btn-sm" onClick = {saveTime}>
                                    Save
                                </Button>
                                <Button className = "btn-outline-secondary f-w-bold border-0 btn-sm" onClick = {saveTime}>
                                    Close
                                </Button>
                            </div>
                        </div>
                    }
                </div>        
                </div>
        
            </div>
        </>
    )
}
export default TimePicker
