import { useState } from 'react'
import { menuItems } from '../../constant/navItems' ;
import {
    Link,
    useHistory
  } from "react-router-dom";
import { logout } from '../../Utils/login';

const SideBar=(props)=>{
    const history = useHistory();
    const [ page, setPage ] = useState ('');
    //localStorage.getItem('menu') ? localStorage.getItem('menu').split(',') :  
    
    const changePage = currentPage =>{
        setPage ( currentPage )
        console.log (currentPage)
    }

    const onLogout = () => {
        logout();
        localStorage.removeItem('role');
        history.push('/login');
    }

    


    
    const menuList = props.menuList;
    return(
        <ul className="navbar-nav">
            {
                menuList.map((currentValue)=>{
                    const activeClass = history.location.pathname === menuItems[currentValue].router ? 'active' : '';
                    return (
                    <li key={currentValue.label}>
                        <Link className = {'nav-link fw-600 '+ activeClass} to = { menuItems[currentValue].router } onClick = { () => changePage ( menuItems[currentValue].label) }>
                            {menuItems[currentValue].icon && <i className={"f-s20 "+menuItems[currentValue].icon}></i>}
                            <span>{menuItems[currentValue].label}</span>
                        </Link>
                    </li>
                    )
                })
            }
            <li key={"logout"}>
                <a className = {'nav-link fw-600'} onClick={onLogout}>
                    <i className="f-s20 bi bi-arrow-bar-left"></i>
                    <span>Logout</span>
                </a>
            </li>
        </ul>
    )
}
export default SideBar