import axios from "axios"
const BaseUrl = process.env.REACT_APP_BASEURL;

export const getShifts = (params) => {
   const url = BaseUrl+'workingHours' 
   const token = localStorage.getItem ('token') || ''
   return axios ( {
       method : 'get',
       url,
       headers : {
           Authorization : 'Bearer '+token 
       },
       params

   } )
}

export const createShift = (data) => {
    const url = BaseUrl+'workingHours'
    const token = localStorage.getItem('token') || ''
    return axios ({
        method : 'post',
        url,
        headers : {
            Authorization : 'Bearer '+token
        },
        data
    })
}

export const deleteShift = (id) => {
    const url = BaseUrl+'workingHours/'+id
    const token = localStorage.getItem('token') || ''
    return axios ({
        method : 'delete',
        url,
        headers : {
            Authorization : 'Bearer '+token
        }
    })
}