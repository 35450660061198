const CheckBox=(props)=>{
    const { label, name, checked = false, value, className = ''} = props

    const onChangeHandler = (event)=>{
        if(props.onChange){
            props.onChange(event);
        }
    }
    return(
        <>
            <div className = "form-check form-check-custom">

                <input className = {"form-check-input " + className} type = "checkbox" value = {value} defaultChecked = {checked} onChange = {onChangeHandler} name = {name} />

                {label && <label className = {"form-check-label " + className } > {label || value } </label>}

            </div> 

        </>
    )
}
export default CheckBox