import { useEffect, useState, useRef } from "react";
import {useHistory} from 'react-router-dom';
//componenets
import Layout from "../Components/Layout";
import PageHeader from "../Components/Common/PageHeader";
import Accordion from "../Components/Common/Accordion";
import Form from '../Components/Common/Form' ;
import QRCodeGenerator from '../Components/Common/QRCodeGenerator';
import AutoComplete from "../Components/Common/Autocomplete";
import Button from "../Components/Common/Button";
import Status from "../Components/Common/Status";
import EditProfilePicture from "../Components/EditProfilePicture";
import FormLabel from "../Components/Common/FormLabel";
import ModalFooter from '../Components/Common/ModalFooter';
import Actions  from "../Components/Common/Actions" ;
import ErrorMessage from "../Components/Common/ErrorMessage";
//utils
import {isAutoCompleteValid} from '../Utils/isValidAutoComplete';
import {generateUUID} from '../Utils/generateUUID';
import {validation} from '../Utils/validation';
import { splitPascalCase } from "../Utils/splitPascalCase";
//services
import { getBranch } from '../services/branch';
import { getTeams } from '../services/team'; 
import { getShifts } from '../services/shift' ;
import { getDesignationList } from '../services/designation' ;
import {getLeavesList} from '../services/leave' ;
import { updateEmployee as updateEmployeeService, getEmployeesListService, getShiftDetail } from '../services/employee' ;
import {getCheckinTypes} from '../services/checkinTypes' ;
// constant
import {employeeStatusList, gpsTrackingList} from '../constant/employee' ;
import {shiftTypesList} from '../constant/shiftTypes' ;

const EditEmployee = (props) => {    
    const router = useHistory();
    // state for store the updated employee details
    const [ employee, setEmployee ] = useState({}) ;
    //state for errormessage
    const [ errorMessage, setErrorMessage ] = useState({}) ;
    // state for set services
    const [ branchList, setBranchList ] = useState([]) ;
    const [ teamList, setTeamList ] = useState([]) ;
    const [ shiftList, setShiftList ] = useState([]) ;
    const [ designationList, setDesignationList ] = useState([]) ;
    const [ leaveTypeList, setLeaveTypeList] = useState([]) ;
    const [ checkinTypes, setCheckinTypes ] = useState([]) ;
    const [ isShowChangeShift, showChangeShift] = useState(false);
    // state for set status
    const [status, setStatus] = useState({ showStatus : false, spinner : false ,success : false, successMessage : '' }) ;
    // state for show and hide accordians body
    const[isShowAccordions, setIsShowAccordions] = useState([true, true, true]);

    const services = ()=>{

        const pagination = { total:100, itemsPerPage:100, page:1};
        getBranch(pagination).then( (results)=> {
            let newBranchList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            setBranchList([...newBranchList]);
            console.log(newBranchList)
        }).catch(errorHandleService);

        getTeams(pagination).then( (results)=> {
            let newTeamList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            setTeamList([...newTeamList]) ;
        }).catch(errorHandleService);

        getShifts(pagination).then( (results)=> {
            let newShiftList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            console.log(newShiftList)
            setShiftList([...newShiftList]);
        }).catch(errorHandleService);

        getDesignationList(pagination).then( (results)=> {
            let newDesignationList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            setDesignationList([...newDesignationList]);
        }).catch(errorHandleService);

        getLeavesList(pagination).then((results)=>{
            let newLeaveList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            setLeaveTypeList([...newLeaveList]);
        });

        getCheckinTypes().then((results)=> {
           const checkinType = results.data.map((type)=> {
                return { ...type, value : type.id };
            })
            setCheckinTypes([...checkinType])
        });
       
    }

    const errorHandleService = (error) => {
        if(error.response.status == 401) {
            router.push('/Login') ;
        }
    }

    useEffect(async ()=> {
            services();
            if(props.match.params.id) {
                Promise.all([getEmployeesListService(props.match.params.id), getShiftDetail(props.match.params.id)]).then(([employeeDetail, shiftDetailResult])=>{
                    const {id, employee_id="", first_name, gps, last_name, mail_id, mobile, qr_code, dob,  checkin_type_id, workspace_id, workspace_name, team_id, team_name, designation_id, 
                    designation_name, shift_id, leave_type_id, leave_type_name, salary, address, timezone, created_at, updated_at, status} = employeeDetail.data;
                    
                    const employeeObj = {...employee, firstName:first_name, lastName:last_name, email:mail_id, checkinType:checkin_type_id, gps, branch:{  id:workspace_id, name:workspace_name}, team: {id:team_id, name:team_name }, 
                    designation: { id: designation_id, name: designation_name}, salary, employeeStatus: status, QRCode: qr_code, 
                        leaveType:{ id: leave_type_id, name: leave_type_name}, shift_id, address, dob, mobile, shiftDetail: shiftDetailResult.data };
                    setEmployee(employeeObj);
                    console.log(employeeObj);
                });
            }
    },[]) ;
    // edit employee onChange handler
    const onChangeHandler = (event, key) =>{
        const newEmployee = employee ;
        const newErrorMessage = errorMessage ;
        newEmployee[key] = event.target.value ;
        newErrorMessage[key] = '' ;
        setEmployee({...employee, ...newEmployee}) ;
        setErrorMessage({...newErrorMessage}) ;
    }

    // add list item functions (add shift)
    const addShiftField = ()=> {
        const newEmployee = {...employee} ;
        newEmployee.selectShift.push({ name : '',key : generateUUID()});
        setEmployee({...employee, ...newEmployee}) ;
    }

    const deleteShiftField = (key)=> {
        const newEmployee = {...employee};
        const index = newEmployee.selectShift.findIndex((shift)=> shift.key == key);
        newEmployee.selectShift.splice(index, 1);
        setEmployee({...employee, ...newEmployee});
    };

    const shiftOnSelect = (value, action, key)=> {
        let newEmployee = {...employee};
        newEmployee.new_shift = value;
        setEmployee({...newEmployee});
    };

    const autoCompleteOnSelect = (value, action, key)=>{
        let newEmployee = {...employee};
        let newErrorMessage = {...errorMessage};
        newErrorMessage[key] = '';
        newEmployee[key] = {...value};
        setEmployee({...employee, ...newEmployee});
        setErrorMessage({...newErrorMessage});
    };
    const parentDiv = useRef();

    const validateSelectShift = ()=>{
        let inValidMessage = {};
        let isValid = true;
        employee.selectShift.map((shift)=>{
            if(!isAutoCompleteValid(shift, shiftList)){
                inValidMessage[shift.key] = 'Please select this field';
                isValid = false;
            };
        });
        console.log('shift', isValid)
        return {message : inValidMessage, isValid : isValid}
    };

    const validateBranchField = ()=>{
        let inValidMessage = '';
        let isValid = true;
        if(!employee.branch || !isAutoCompleteValid(employee.branch, branchList)){
            inValidMessage = 'Please select this field';
            isValid = false;
        }
        return {message : inValidMessage, isValid : isValid};
    };

    const mergeValidationResults = ()=>{
        const inputFields = basicInformation.col1.concat( otherInformation.col1);
        let isValid = true;
        let inValidMessages = {};
        
        //validation results
        const branchValidation = validateBranchField();
        //const shiftValidation = validateSelectShift()
        const otherFieldValidation = validation(inputFields, employee);
        // -*- validation results -*-
        

        inValidMessages = {...otherFieldValidation.errorMessage};
        //Validating shift info 
        let shiftIsValid = true;
        if(isShowChangeShift) {
            inValidMessages.selectShift = "";
            if(!employee.new_shift) {
                inValidMessages.selectShift +="Select new shift ";
                shiftIsValid = false;
            }
            if(!employee.new_shift_start_from) {
                inValidMessages.selectShift +="Enter new shift start date";
                shiftIsValid = false;
            }
        }
        else {
            inValidMessages.selectShift = "";
            shiftIsValid = true;
        }
        if(branchValidation.message) inValidMessages.branch = branchValidation.message;

        

        isValid = branchValidation.isValid && otherFieldValidation.isValid && shiftIsValid;
        
        return {isValid : isValid, messages : inValidMessages};
    };

    const updateEmployeeValidation =()=>{
        const validationResult = mergeValidationResults();
        let inValidMessages = validationResult.messages;
        let isUpdateEmployee = validationResult.isValid;
        if(!isUpdateEmployee){
            const errorMessageLabels = Object.keys(inValidMessages);
            inValidMessages.common = 'Please check this fields ' + errorMessageLabels.map((label)=> splitPascalCase(label)).join(', ');
            setIsShowAccordions([true, true, true]);
        }
        setErrorMessage({...inValidMessages});
        return isUpdateEmployee;
    };

    const multiOnSelect = (value, action, key)=>{
        let newEmployee = {...employee};
        let newErrorMessage = {...errorMessage};
        newEmployee[key] = [...value];
        newErrorMessage[key] = '';
        setEmployee({...newEmployee});
        setErrorMessage({...newErrorMessage});
    };

    const saveImage = (imageURL)=> {
        let newEmployee = employee;
        newEmployee.imageURL = imageURL;
        setEmployee({...employee, newEmployee});
    };

    const employeeData = ()=>{
        const {firstName, lastName, email, checkinType, gps, branch, team, designation, salary, employeeStatus, QRCode, leaveType, shift_id, address, dob, mobile, new_shift, new_shift_start_from} = employee ;

        const data = {first_name : firstName, last_name : lastName, mail_id : email, mobile, checkin_type_id : checkinType, gps : gps, qr_code: QRCode, QRCode, workspace_id : branch.id, team_id : team.id, designation_id : designation.id, shift_id, leave_type_id : leaveType.id, salary : salary, status : employeeStatus, address, dob } ;
        if(isShowChangeShift) {
            data.new_shift = new_shift;
            data.new_shift_start_from = new_shift_start_from;
        }
        return data;
    };

    const onChangehift = () => {
        showChangeShift(true)
    }

    const onHideChangehift = () => {
        setEmployee({...employee, new_shift: false, new_shift_start_from:""})
        showChangeShift(false)
    }

    const updateEmployee = ()=> {
        const isUpdateEmployee = updateEmployeeValidation(); 
        if(isUpdateEmployee){

            setStatus({ ...status, showStatus : true ,spinner : true });
            const data = employeeData();
            console.log(data)
            updateEmployeeService(data, props.match.params.id).then((results) => {
                console.log(results)
                setStatus({ ...status, showStatus : true, spinner : false, success : true, successMessage : 'Employee updated successfully' });
            }).catch()
        }
    };
    const showAccordionBody =(index)=>{
        const showAccordions = isShowAccordions;
        showAccordions[index] = !isShowAccordions[index];
        setIsShowAccordions([...showAccordions]);
    };

    const routeEmployeePage = () => {
        router.push('/Employees') ;
        setStatus({ showStatus : false, spinner : false, success : false, successMessage : '' }) ;
    };

    const regenarateQRCode = () => {
        const newEmployee = employee ;
        newEmployee.QRCode = generateUUID() ;
        setEmployee({...employee, ...newEmployee}) ;
    };

    const basicInformation = {
        col1 : [
            { row : [{ type : 'text', key : 'firstName' }, { type : 'text', key : 'lastName'}] },
            { row : [ {type : 'text', key : 'email', validationType : 'email' },  {type : 'text', key : 'mobile', label : 'Mobile number', isMandatory : false, emptyCheck : false}]}
        ]
    };

    const otherInformation = {
        col1 : [
            { row :
                [
                    {type : 'autocomplete', key : 'designation', options : designationList, emptyCheck : false, validationType : 'autocomplete'},
                    {type : 'autocomplete', key : 'branch', options : branchList, emptyCheck : false, validationType : 'autocomplete'}
                ]
    
            },
            { row : 
                [
                    {type : 'autocomplete', key : 'team', options : teamList, emptyCheck : false, validationType : 'autocomplete'},
                    {type : 'autocomplete', key : 'leaveType', options : leaveTypeList, emptyCheck : false, validationType : 'autocomplete'}
                ]
            
            },
            { row : [
                {type : 'text', key : 'salary'},
                { type : 'select', key : 'checkinType', options : checkinTypes}
            ] },
            { row : [
                {type : 'select', key : 'employeeStatus', options : employeeStatusList},
                { type : 'radio', key : 'gps', label : 'GPS tracking' ,  radios : gpsTrackingList }
            ]},
            { row : [
                { type : 'date', key : 'dob', label : 'Date of birth', isMandatory : false, emptyCheck : false},
                {type : 'textarea', key : 'address', isMandatory : false, emptyCheck : false}
            ]}
        ]
    };
    const shiftInformation =  {
        col : [
            { row : [
                {type : 'select', key : 'shiftRotationType', sizeClass : 'col-6', options : shiftTypesList}, 
                {type : 'text', key : 'rotationCycle', sizeClass : 'col-lg-3 col'}
                    ] 
            }
        ]
    };

    const actions = [{label : 'Back', className : 'btn-secondary', onClick:routeEmployeePage }, {label : 'Save', className : 'btn-primary', onClick : updateEmployee}] ;

    const renderBasicInformation = ()=> {
        return  (
            <div className = 'row'>
                {/* <div className = 'col-12 col-lg-3'>
                    <EditProfilePicture src = {employee.imageURL} title = 'Change Profile Picture' saveCroppedImage = {saveImage}/>
                </div> */}
                <div className = 'col-12 col-lg'>
                    <div className = 'row'>
                        <div className = 'col-md-12'>
                            <Form rows = {basicInformation.col1} value = {employee} errorMessage = {errorMessage} onChange = {onChangeHandler}  />
                        </div>
                    </div>
                </div>
            </div>
        )
    };
    const renderOtherInformation = ()=> {
        return(
            <div className = 'row'>
                <div className = 'col-lg col-12 '>
                    <div className = 'row'>
                        <div  className = 'col-md-12'>
                            <Form rows = {otherInformation.col1} value = {employee} errorMessage = {errorMessage} onChange = {onChangeHandler} autoCompleteOnSelect = {autoCompleteOnSelect} multiOnSelect = {multiOnSelect}/>
                        </div>
                    </div>
                </div>
                {/* <div className = 'col-lg-3 col-12'>
                    <FormLabel label = 'QR Card' mandatory = {false}/>
                    <QRCodeGenerator download = {true} size = {150} value = {employee.QRCode}/>  
                </div> */}
            </div>
        )
    };
    
    const renderShiftInforation =()=>{
        const currentShift = shiftList && shiftList.length ? shiftList.find(shiftObj => employee.shiftDetail && employee.shiftDetail.shift_id === shiftObj.id) : null;
        return currentShift ? (
            <div className = 'd-flex'>
                <div className = 'col-lg-6'>
                    <div className="row align-items-center">
                        <div className="col">
                            <div>
                                <label className="label">Current Shift:</label>
                                <span>{currentShift.name} from {employee.shiftDetail.start_from}</span>
                            </div>
                        </div>
                        <div className="col">
                            {!isShowChangeShift && <Button className = 'btn-primary btn-sm' onClick={onChangehift}>Change Shift</Button>}
                        </div>
                    </div>
                    {isShowChangeShift && (<div className="row align-items-center">
                        <div className="col">
                                <label className="label mb-1">Select Shift</label>
                                <AutoComplete name = {""} value = {employee.new_shift || ""} onSelect = {(value, action)=> shiftOnSelect(value, action)} options = {shiftList}/>
                        </div>
                        <div className="col">
                            <label className="label">Current Shift:</label>
                            <input type={"date"} value={employee.new_shift_start_from || ""} onChange={(e)=>onChangeHandler(e, "new_shift_start_from")}/>
                        </div>
                        <div className="col"><Button className = 'btn-secondary btn-sm' onClick={onHideChangehift}>cancel</Button></div>
                        <ErrorMessage label = {errorMessage.selectShift && errorMessage.selectShift}/>
                    </div>)}
                </div>
            </div>
        ) : null
    };
    const accordion = [
        {title : 'Basic Infomation', render : renderBasicInformation()},
        {title : 'Other Infomation', render : renderOtherInformation()},
        {title : 'Shift Infomation', render : renderShiftInforation()},
    ];
    return(
        <Layout>
            <div ref = {parentDiv} className = { status.showStatus ? 'd-none' : 'd-block'} >
                <PageHeader title = "Edit Employee"/>
                {accordion.map((accordionBody, index)=>{
                    return(
                        <Accordion onClick = {()=>showAccordionBody(index)} key = {index} title = {accordionBody.title} showBody = {isShowAccordions[index]} className = 'border-0 border-bottom rounded-0' >
                            {accordionBody.render}
                        </Accordion>
                    )
                })}
                <ModalFooter>
                    <Actions actions = {actions}/>
                </ModalFooter>
            </div>
            <div className = {status.showStatus ? 'd-block mt-100' : 'd-none'}>
                <Status spinner = {status.spinner} success = {status.success} successMessage = {status.successMessage} onClick = {routeEmployeePage}/>
            </div>
        </Layout>
    )
}

export default EditEmployee