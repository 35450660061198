export const customReports = [
    {
        name : 'Birthday List',
        id : 1, 
        columns : [
            {label : 'team', key : 'team', value : 'team', id : 2},
            {label : 'branch', key : 'branch' , value : 'branch', id : 3}
        ]
    },
    {
        name : 'Team',
        id : 2, 
        columns : [
            {label : 'birthday', key : 'birthday', value : 'birthday', id : 1},
            {label : 'team', key : 'team', value : 'team', id : 2},
            {label : 'branch', key : 'branch' , value : 'branch', id : 3}
        ]
    },
    {
        name : 'Team 1',
        id : 3, 
        columns : [
            {label : 'birthday', key : 'birthday', value : 'birthday', id : 1},
            {label : 'team', key : 'team', value : 'team', id : 2},
            {label : 'branch', key : 'branch' , value : 'branch', id : 3}
        ]
    },
    {
        name : 'Team 2',
        id : 4, 
        columns : [
            {label : 'birthday', key : 'birthday', value : 'birthday', id : 1},
            {label : 'team', key : 'team', value : 'team', id : 2},
            {label : 'branch', key : 'branch' , value : 'branch', id : 3},
            {label : 'shift', key : 'shift',value : 'shift', id : 15},
            {label : 'shift', key : 'shift', value : 'shift', id : 16}
        ]
    },
]