//components
import FormLabel from "./FormLabel";

const Select=(props)=>{
    const { options = [], className = " ", onChange, label, name, value, disabled = false,mandatory = true, keyValue=false } = props;

    const onChangeHandler=(event)=>{
        if(onChange) onChange(event)
    }

    return(
        <>
            {label && <FormLabel label = {label} mandatory = {mandatory}/>}
            <select disabled = {disabled} value = {value} className = {"form-select f-s13  border "+className} name = {name} onChange = {onChangeHandler}>
                {options.length &&
                    options.map((currentValue, index)=>{
                        return(
                            <option key={ keyValue ? index : currentValue.value} value={currentValue.value}>{currentValue.name}</option>
                        )
                    })
                }
            </select>
       </>
    )
}
export default Select;