import Button from '../Common/Button'

const PageHeader = (props) => {
    const { title, label, onClick } = props
    return (
        <>
            <div className = 'd-flex align-items-center justify-content-between px-3 navbar border-bottom'>
                <span className = 'page-title'>{title}</span>
                
                {label && 
                    <Button  onClick = {onClick} className = 'btn-primary f-s14 rounded-pill f-w-600 btn-sm'>{label}</Button>
                }
                
            </div>
        </>
    )
}

export default PageHeader