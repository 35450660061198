import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
//components
import Actions from '../Common/Actions';
import Modal from './Modal';

const ImageCropper = (props)=>{
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: 'px', width: props.minWidth || 100, aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [croppedImage, setCroppedImage] = useState(props.croppedImage);
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const saveCroppedImage = ()=>{
    if(props.saveCroppedImage) props.saveCroppedImage(croppedImage);
  }; 

  const hideImageCropper = ()=>{
    if(props.hideImageCropper){
      props.hideImageCropper();
    };
  };

  useEffect(() => {
    if (!completedCrop || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = document.createElement('canvas');
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    canvas.toBlob(blop => {
      if(!blop){
        return;
      }
      const fileUrl = window.URL.createObjectURL(blop);
      console.log("blopblopblopblopblopblopblopblopblop", blop)
      window.URL.revokeObjectURL(fileUrl);
      setCroppedImage(fileUrl);
      console.log("fileUrlfileUrlfileUrlfileUrl222", fileUrl)
    });
  }, [completedCrop]);
  const {minWidth = 100, maxWidth = 200, title } = props;
  console.log("upImg", upImg)
  return (
      <Modal title = {title} className = 'modal-lg' hideModal = {hideImageCropper}>
          <div>
            <div className = 'row'>
              <div className = 'col-md-8'>
                <div className = 'cropped-area'>
                  {!upImg && 
                      <label className = 'w-100 h-100 cursor-pointer'>
                        <div className = 'w-100 h-100 d-flex justify-content-center align-items-center'>
                          <input className = 'd-none' type="file" accept="image/*" onChange = {onSelectFile}/>
                          <div className = 'text-center'>
                            <span><i className="bi bi-cloud-arrow-up-fill text-primary f-s28"></i></span>
                            <div className = 'f-w-600'>Upload Image</div>
                          </div>
                        </div>    
                      </label>      
                  }
                  {upImg && 
                    <div className = 'd-flex justify-content-center align-items-center h-100'>
                      <ReactCrop
                        className = 'h-100 image-cropper'
                        src={upImg}
                        minWidth = {minWidth}
                        maxWidth = {maxWidth}
                        onImageLoaded={onLoad}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                      />
                    </div>          
                  }
                </div>   
              </div>
              <div className = 'col-md-4'>
                <div className = 'd-flex justify-content-center d-none d-md-block'>
                  <div className = 'avatar avatar-lg border'>
                    <img src = {croppedImage}/>
                  </div>
                </div>
                <div className = 'alert alert-info mt-4'>
                  <div className = 'alert-heading mb-1'>Important Notes</div>
                      <ul>
                        <li className = 'ml-20'>Only .jpg/.jpeg and .png format files are allowed.</li>
                        <li className = 'ml-20'>File size should be max of 500KB.</li>
                      </ul>
                </div>
              </div>
            </div>
            <div className = 'd-flex justify-content-between mt-1 mb-1'>
              {upImg && 
                  <label className = 'mt-2 mb-2 cursor-pointer'>
                    <span>
                      <input className = 'd-none' type="file" accept="image/*" onChange={onSelectFile}/>
                      <span className = 'text-primary f-w-600'>
                        <i className = "bi bi-cloud-arrow-up-fill f-s16"></i>
                        <span className = 'ml-4'>Upload again</span>
                      </span>
                    </span>
                  </label>         
                }
                <div className = 'ml-auto'>
                  <Actions actions = {[{label : 'Close', className : 'btn-secondary', onClick : hideImageCropper}, {label : 'Save', className : 'btn-primary', onClick : saveCroppedImage}]} />
                </div>
            </div> 
          </div>
      </Modal>
  );
}
export default ImageCropper;
