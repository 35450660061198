import {splitPascalCase} from '../../Utils/splitPascalCase'
const ShowDetails = (props) => {
    const {col = [], value = {}} = props
    // label and value inline 
    return(
    col.map((column, rowIndex) => {
        return(
            <div key = {rowIndex} className = 'd-flex mb-2'>
                {
                    column.row.map((col, colIndex) => {
                        const label = col.label || splitPascalCase(col.key)
                        return( 
                            <div key = {colIndex} className = {'col mb-2 border-start border-2 border-info'}>
                                <div className = 'ml-10'>
                                    <label className = {'label d-block mb-1' }>{label}</label>
                                    <label className = {'d-block f-s14 f-w-600'}>{ value[col.key]}</label>
                                </div>     
                            </div>
                        )
                    })
                }
            </div>

        )
    })
    )
}
export default ShowDetails