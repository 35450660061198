import { useState } from 'react';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
//https://www.npmjs.com/package/react-sortable-hoc
const ReOrderList = (props)=>{
    const {items = []} = props;
    const onSortEnd = (element, f)=>{
        if(props.onSortEnd){
            props.onSortEnd(element.oldIndex, element.newIndex);
        }
    };

    const SortableItem = sortableElement((item) => <><li  className = 'p-2 border mb-1 d-flex justify-content-between align-items-center border'><span>{item.value}</span><span><i className="bi bi-list f-s20"></i></span></li></>);

    const SortableContainer = sortableContainer(({children}) => {
        return <ul className = 'list-group' >{children}</ul>;
    });

    return(
        <>
        {items.length != 0 && 
            <SortableContainer onSortEnd={onSortEnd} helperClass = 'sort-element'>
                { items.map((value, index) => (
                <SortableItem key ={`item-${value.id}`} index = {index} value={value.label} />
                ))}
            </SortableContainer>        
        }

        </>
    )
}
export default ReOrderList;