export const loginError = {
    organizationId : "",
    email : "Invalid email",
    password : "Password length should be minimum 6 and must have one uppercase, lowercase and special character or number",
    network : 'Network error please check your connection'
}

export const signupError = {
    organizationId : '',
    email : 'Invalid email',
    password : "Password length should be minimum 6 and must have one uppercase, lowercase and special character or number",
    country : 'Please select country',
    mobileNumber : 'Invalid mobile number',
    network : 'Network error please check your connection'
}

export const manageEmployeeError = {
    email : 'Invalid email'
}
