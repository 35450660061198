//components
import Layout from "../Components/Layout";
import PageHeader from '../Components/Common/PageHeader';
import BigCalendar from "../Components/Common/BigCalendar";
import { useEffect, useState } from "react";
const HoliDays = (props)=>{
    const dummyEvents = [
        {
            start : '2021/07/10',
            end : '2021/07/10',
            title : 'diwali'
        },
        {
            start : '2021/07/10',
            end : '2021/07/10',
            title : 'diwali'
        },
        {
            start : '2021/07/10',
            end : '2021/07/10',
            title : 'diwali'
        },
        {
            start : '2021/07/10',
            end : '2021/07/10',
            title : 'diwali'
        },
        {
            start : '2021/07/10',
            end : '2021/07/10',
            title : 'diwali'
        },
        {
            start : '2021/07/10',
            end : '2021/07/10',
            title : 'diwali'
        }
    ];
    const [eventList, setEventList] = useState([]);
    useEffect(()=>{
        const newEventList = [];
        dummyEvents.map((eventDetail)=>{
            newEventList.push({...eventDetail, start : new Date(eventDetail.start), end : new Date(eventDetail.end)});
        });
        console.log(newEventList)
        setEventList([...newEventList]);
    },[true])
    
    const onSelectSlot = (e)=>{
        console.log(e)
    }
    return(
        <Layout>
            <PageHeader title = 'HoliDays'/>
            <div className = 'px-3 mt-2'>
                <BigCalendar events = {eventList} popup = {true}/>
            </div>
            
        </Layout>
    )
}
export default HoliDays;