// import { useRouter } from 'next/router';
//components
import { useEffect, useState } from 'react';
import PageHeader from '../Components/Common/PageHeader';
import Table from '../Components/Common/Table';
import Layout from '../Components/Layout';
import Modal from '../Components/Common/Modal';
import Alert from '../Components/Common/Alert';
import ModalFooter from '../Components/Common/ModalFooter';
import FormLabel from '../Components/Common/FormLabel';
import Actions from '../Components/Common/Actions';
import Input from '../Components/Common/Input';
import CheckBox from '../Components/Common/CheckBox';
import ReOrderList from '../Components/Common/ReOrderList';
import ErrorMessage from '../Components/Common/ErrorMessage';
//constant
import {customReports} from '../constant/customReports';
const Reports = (props)=>{

    const columns = [
        {label : 'birthday', value : 'birthday', id : 1},
        {label : 'team', value : 'team', id : 2},
        {label : 'branch', value : 'branch', id : 3},
        {label : 'shift', value : 'shift', id : 4},
        {label : 'shift', value : 'shift', id : 5},
        {label : 'shift', value : 'shift', id : 6},
        {label : 'shift', value : 'shift', id : 7},
        {label : 'shift', value : 'shift', id : 8},
        {label : 'shift', value : 'shift', id : 9},
        {label : 'shift', value : 'shift', id : 10},
        {label : 'shift', value : 'shift', id : 11},
        {label : 'shift', value : 'shift', id : 12},
        {label : 'shift', value : 'shift', id : 13},
        {label : 'shift', value : 'shift', id : 14},
        {label : 'shift', value : 'shift', id : 15},
        {label : 'shift', value : 'shift', id : 16}
    ];
    const values = [
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1},
        {name : 'siva', branch : 'mech', shift : 'night shift', team : 'teamA', id : 1}
    ];
    // const router = useRouter();

    const [reportList, setReportList] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowAlert, setIsShowAlert] = useState(false);
    const [reportDetails, setReportDetails] = useState({});
    const [availableColumn, setAvailableColumn] = useState([]);
    const [errorMessage, setErrorMessage] = useState({});
    const [modalFor, setModalFor] = useState('')

    useEffect(()=>{
        setReportList([...customReports]);
    },[true])

    const showModal = (createOrEdit, reportInfo)=>{
        if(createOrEdit == 'create'){
            setReportDetails({selectedColumn : []});
            setAvailableColumn([...columns]);
            setIsShowModal(true);
            setModalFor('create');
        }
        else if(createOrEdit == 'edit'){
            setReportDetails({ ...reportInfo, selectedColumn : [...reportInfo.columns] });
            let newAvailableColumn = [...columns];
            newAvailableColumn.map((column, index)=>{
                reportInfo.columns.some((selectedColumn)=>{
                    if(selectedColumn.id == column.id){
                        newAvailableColumn[index] = {...column, checked : true}
                    }
                })
            })
            setIsShowModal(true);
            setAvailableColumn([...newAvailableColumn]);
            setModalFor('edit');
        }
    
    }

    const hideModal = ()=>{
        setIsShowModal(false);
        setReportDetails({});
    };

    const routeGenerateReport = (reportInfo)=>{
        console.log(reportInfo)
       sessionStorage.setItem('reportInfo', JSON.stringify({...reportInfo, rows : values}));
       //router.push('./GenerateReport');
    };

    const header = [
        { key : 'name'},
        { key : "action", label : "Actions",
            actions:[
                { 
                    className : 'btn-outline-success', 
                    label : <i className="bi bi-download"></i>,
                    title : 'download',
                    onClick : routeGenerateReport
                },
                {
                label : <i className="bi bi-pencil-fill"></i>,
                key : "view",
                className : 'btn-outline-primary ml-10',
                onClick : (reportInfo) => showModal( 'edit', reportInfo)
                },
                {
                label : <i className="bi bi-trash-fill"></i>,
                key : "delete",
                className : 'btn-outline-danger ml-10',
                onClick : (reportInfo) => showAlert ( reportInfo )
                }
            ]
        }
    ];

    const checkboxOnChange = (event, columnDetails)=>{
        let newSelectedColumn = [...reportDetails.selectedColumn];
        if(event.target.checked){
            newSelectedColumn.push(columnDetails);
        }
        else{
            const index = newSelectedColumn.findIndex((column)=> column.id == columnDetails.id);
            newSelectedColumn.splice(index, 1);
        }
        setReportDetails({...reportDetails, selectedColumn : [...newSelectedColumn]});
    };

    const onChangeName = (event)=>{
        setReportDetails({...reportDetails, name : event.target.value});
    };

    const onSortEnd = (oldIndex, newIndex)=>{
        let newSelectedColumn = [...reportDetails.selectedColumn];
        const element = newSelectedColumn[oldIndex];
        newSelectedColumn.splice(oldIndex, 1);
        newSelectedColumn.splice(newIndex, 0, element);
        setReportDetails({...reportDetails, selectedColumn : [...newSelectedColumn]});
    };

    const validateInputFields = ()=>{
        let newErrorMessage = {};
        let isValid = true;
        if(!reportDetails.name){
            newErrorMessage.name = 'Name field Should be empty';
            isValid = false;
        }
        if(!reportDetails.selectedColumn || reportDetails.selectedColumn.length == 0){
            newErrorMessage.selectedColumn = 'Plase select minimum one column';
            isValid = false;
        }
        setErrorMessage({...newErrorMessage});
        return isValid;
    };

    const createReport = ()=>{
        let isCreateReport = validateInputFields();
        if(isCreateReport){
            let newReportList = [...reportList];
            newReportList.push({name : reportDetails.name, columns : [...reportDetails.selectedColumn]});
            setReportList([...newReportList]);
        }
    };

    const updateReport = ()=>{
        let isUpdateReport = validateInputFields();
        if(isUpdateReport){
            let newReportList = [...reportList];
            const updatedIndex = newReportList.findIndex((list)=> list.id == reportDetails.id);
            newReportList[updatedIndex] = {...reportDetails, columns : [...reportDetails.selectedColumn]};
            setReportList([...newReportList]);
        }
    }
    const showAlert = (reportInfo)=>{
        setReportDetails({...reportInfo});
        setIsShowAlert(true);
    };
    const hideAlert = ()=>{
        setIsShowAlert(false);
    };
    const deleteReport = ()=>{
        let newReportList = [...reportList];
        const deleteIndex = newReportList.findIndex((report)=>report.id == reportDetails.id);
        newReportList.splice(deleteIndex, 1);
        setReportList([...newReportList]);
        hideAlert();
        console.log(deleteIndex)
    };

    console.log(reportDetails)
    const createActions = [{label : 'Close',className : 'btn-secondary', onClick : hideModal}, {label : 'Save', className : 'btn-primary', onClick : createReport}];
    const editActions = [{label : 'Close',className : 'btn-secondary', onClick : hideModal}, {label : 'Update', className : 'btn-primary', onClick : updateReport}];

    const actions = modalFor == 'create'? createActions : editActions;
    // delete report actions
    const deleteActions = [
        { label : 'Close', onClick : hideAlert , className : 'btn-secondary' },
        { label : 'Delete', onClick : deleteReport , className : 'btn-danger' },
    ];

    return(
        <Layout>
            <PageHeader title = 'Manage Reports' label = 'Create Custom Report' onClick = {()=>showModal('create')}/>
            <Table header = {header} body = {reportList}/>
            {isShowModal &&
                <Modal className = 'modal-lg' title = 'Customize Columns' hideModal = {hideModal}>
                    <Input type = 'text' label = 'Name' value = {reportDetails.name || ''} onChange = {onChangeName}/>
                    <ErrorMessage label = {errorMessage.name}/>
                    <div className = 'row mt-3'>
                        <div className = 'col'>
                            <FormLabel label = 'Available Columns' mandatory = {false}/>
                            <div className = 'draggble-column mt-1'>
                                {availableColumn.map((column, index)=>{
                                    return (
                                        <div className = 'pt-1 pb-1'>
                                            <CheckBox key = {index} label = {column.label} value = {column.value} checked = {column.checked} onChange = {(event)=>checkboxOnChange(event, column)}/>
                                        </div>
                                    )
                                    
                                })}
                            </div> 
                        </div>
                        <div className = 'col'>
                            <FormLabel label = 'Selected Columns' mandatory = {false}/>
                            <div className = 'draggble-column mt-1'>
                                <ReOrderList onSortEnd = {onSortEnd} items = {reportDetails.selectedColumn}/>
                            </div>
                        </div>
                    </div>
                    <ErrorMessage label = {errorMessage.selectedColumn}/>
                    <ModalFooter>
                            <Actions actions = {actions}/>
                    </ModalFooter>
                </Modal>
            }
            {isShowAlert && 
                <Alert label = 'Are you sure delete this report?' actions = {deleteActions}/>
            }
        </Layout>
    )
}
export default Reports;