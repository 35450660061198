import axios from 'axios';
const BaseUrl = process.env.REACT_APP_BASEURL;



export const getTeams  = (params) => {
    const token = localStorage.getItem ('token') || '';
    const url = BaseUrl+'teams';
    return axios({
        method: 'get',
        url ,
        headers: {
             Authorization: "Bearer "+token
        },
        params
      });
      
  }

  export const createTeam = (data) => {
    const token = localStorage.getItem ('token') || '';
    const url = BaseUrl+'teams';
      return axios ({
          method : 'post',
          url,
          headers : {
              Authorization : 'Bearer ' + token 
          },
          data
      })
  }

  export const updateTeam = (data) => {
    const token = localStorage.getItem ('token') || '';
    const url = BaseUrl+'teams/'+data.id
    return axios ({
        method : 'put',
        url,
        headers : {
            Authorization : 'Bearer '+token
        },
        data
    })
  }

  export const deleteTeam = (id) => {
    const token = localStorage.getItem ('token') || '' ;
    const url = BaseUrl+'teams/'+id ;
    return axios ({
        method : 'delete',
        url,
        headers : {
            Authorization : 'Bearer '+token
        },
    })
  }