const FormSecondaryHeader=(props)=>{
    return(
        <>
        <nav className="navbar navbar-light bg-white">
            <div className="container">
                <a className="navbar-brand text-primary f-w-bold" href="#">
                    CheckIn
                </a>
            </div>
        </nav>
        </>
    )
}
export default FormSecondaryHeader;