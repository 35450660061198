//components
import Layout from '../Components/Layout' ;
import PageHeader from '../Components/Common/PageHeader' ;
import Table from '../Components/Common/Table' ;
import Modal from '../Components/Common/Modal' ;
import ModalFooter from '../Components/Common/ModalFooter' ;
import Form from '../Components/Common/Form' ;
import Status from '../Components/Common/Status' ;
import Actions from '../Components/Common/Actions' ;
import Alert from '../Components/Common/Alert' ;
//utils
import { splitPascalCase } from '../Utils/splitPascalCase' ;
import { validation } from '../Utils/validation';
import { useState, useEffect } from 'react' ;
import { getDesignationList as getDesignationListService, createDesignation as createDesignationService, updateDesignation as updateDesignationService, deleteDesignation as deleteDesignationService } from '../services/designation' ;

const Designations = (props) => {
    // state for get team from server and send to table
    const [ designationList, setDesignationList ] = useState ([]);
    // state show and hide modal
    const [ isShowModal, setIsShowModal ] = useState (false) ;
    // state for change modal add or edit
    const [ addEdit, setAddEdit ] = useState () ;
    // state for add new shift and valitation designation
    const [ designation , setDesignation ] = useState ({ newDesignation : {}, errorMessage : {} }) ;
    // state show and hide the alert
    const [ isShowAlert, setIsShowAlert ] = useState(false) ;
    // state for status add end edit team
    const [ status, setStatus ] = useState({ showStatus : false, spinner : false , success : false, successMessage : ''}) ;

    const [paginationDetail, setPaginationDetail] = useState({itemsPerPage:10, page:1, total:10});

    const [isLoader, setLoader] = useState(false);

    const roleName = localStorage.getItem('role');

    
    // get designetion list from server
    useEffect(() => {
        setLoader(true);
        getDesignationListService().then((results) => {
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            setDesignationList(result);
            setPaginationDetail({itemsPerPage, page, total});
            setLoader(false);
        }).catch(()=>{
            setLoader(false);
        }) 
            
    },[true])

    const showModal = ( addEdit, designationInfo ) => {
        setIsShowModal(true)
        if( addEdit == 'addDesignation') {
            setAddEdit('addDesignation')
            setDesignation({ newDesignation : {}, errorMessage : {} })
        }
        else{
            setAddEdit('editDesignation')
            const newDesignation = {} ;
            newDesignation.name = designationInfo.name ;
            newDesignation.description = designationInfo.description ;
            newDesignation.id = designationInfo.id ;
            setDesignation({...designation, newDesignation})
        }
    }

    const hideModal = () => {
        setIsShowModal(false)
        setDesignation ({ newDesignation : {}, errorMessage : {} })
        setStatus ( { ...status, showStatus : false, success : false, successMessage : '' } )
    } 

    const designationOnChange = ( event, key) => {
        const newDesignation = { ...designation.newDesignation } ;
        const errorMessage = { ...designation.errorMessage } 
        newDesignation [key] = event.target.value
        errorMessage [key] = ''
        setDesignation ({ ...designation, newDesignation, errorMessage })
    }

    const addDesignation = () => {
        let errorMessage = {}

        let isCreateDesignation = true

        const validationResult = validation(inputFields, designation.newDesignation) ;
        errorMessage = {...errorMessage, validation : validationResult.errorMessage} ;
        isCreateDesignation = validationResult.isValid ;

        if(isCreateDesignation){
            
            const { name, description } = designation.newDesignation ;
            const data = { name : name, description : description }

            setStatus ( { ...status, spinner : true } )
            setLoader(true);
            createDesignationService(data).then((results) => {
                const designationLists = [...designationList]
                designationLists.push({ id : results.data.insertId, name : name, description : description })
                setDesignationList( [ ...designationLists ] )
                setLoader(false);

                setStatus ( { showStatus : true, spinner : false , success : true, successMessage : 'Designation created successfully' } )

            }).catch( (error)=>{
                console.log(error)
                setLoader(false);
            })
        }

    }

    const updateDesignation = () => {
        let errorMessage = {}
        let isUpdateDesignation = true
        const validationResult = validation(inputFields, designation.newDesignation) ;
        errorMessage = {...errorMessage, ...validationResult.errorMessage} ;
        isUpdateDesignation = validationResult.isValid ;
            setDesignation( { ...designation, errorMessage } )
        if ( isUpdateDesignation ) {
            setStatus ( { showStatus : true, isLoader : true } )
            const { name, description, id } = designation.newDesignation
            const data = { name : name, description : description, id : id }

            setLoader(true);

            updateDesignationService (data).then ( (results)=>{
                const designationLists = [...designationList];
                const designationIndex = designationLists.findIndex ( designations => designations.id == designation.newDesignation.id )
                designationLists[designationIndex] = { ...designationLists[ designationIndex ], name : name, description : description };
                setDesignationList ( [...designationLists] )
                setLoader(false);

                setStatus ( { showStatus : true, spinner : false , success : true, successMessage : 'Designation updated successfully' } )

            }).catch((error)=>{
                setLoader(false);
                console.log('error:', error)
            });
        }
    }

    const showAlert = (designationInfo) => {

        const newDesignation = { id : designationInfo.id }
        
        setDesignation( { ...designation, newDesignation } )

        setIsShowAlert(true) 

    }
    const hideAlert = () => {
        setIsShowAlert(false)
    }

    const deleteDesignation = () => {
        setLoader(true);
        deleteDesignationService (designation.newDesignation.id).then((results)=>{

            const designationLists = [ ...designationList ] ;
            const designationIndex = designationLists.findIndex ( (designations) => designations.id == designation.newDesignation.id ) ;
            designationLists.splice( designationIndex, 1 )
            setDesignationList ( [ ...designationLists ] )
            setLoader(false);

            hideAlert ()
        }).catch ((error) => {
            setLoader(false);
            console.log(error)
        })
    }

    const onPaginationRoutePage = (page) => {
        const updatePagination = { ...paginationDetail, page};
        setPaginationDetail(updatePagination);
        setLoader(true);
        getDesignationListService(updatePagination).then((results) => {
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            setDesignationList(result);
            setLoader(false);
        }).catch(()=>{
            setLoader(false);
        })
    }

    const onChangeRowsPerPage = (itemsPerPage) => {
        const updatePagination = { ...paginationDetail, itemsPerPage, page:1};
        setPaginationDetail(updatePagination);
        setLoader(true);
        getDesignationListService(updatePagination).then((results) => {
            const employeeList = [];
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            setDesignationList(result);
            setLoader(false);
        }).catch(()=>{
            setLoader(false);
        })
    }

    const actionsBtn = 
    { key : "action", label : "Actions",
        actions : [
            {
            label : <i className="bi bi-pencil-fill"></i>,
            key : "edit",
            className : 'btn-outline-primary',
            onClick : (designationInfo) => showModal( 'editDesignation', designationInfo )
            },
            {
            label : <i className="bi bi-trash-fill"></i>,
            key : "delete",
            className : 'btn-outline-danger ml-10',
            onClick : (designationInfo) => showAlert(designationInfo)
            }
        ]
    };

    const header = [
        { key : 'name'},
        { key : 'description'}
    ]

    if(roleName === 'admin') header.push(actionsBtn);

    //  Designation input Fields
    const inputFields = [
        { row : [ { type : 'text', key : 'name' }] },
        { row : [ { type : 'textarea', key : 'description' }] } 
    ]

    // change modal add or editt
    const modalTitle = addEdit == 'addDesignation' ? 'Create new Designation' : 'Edit Designation' ;

    const add = [ 
        {label : "Close", className : "btn-secondary", onClick : hideModal},
        {label : "Save", className : "btn-primary", onClick : addDesignation }
    ] ;

    const edit = [  
        {label : "Close", className : "btn-secondary", onClick : hideModal },
        {label : "Save", className : "btn-primary", onClick : updateDesignation }
    ] ;

    const actions = addEdit == 'addDesignation' ? add : edit ;

    // designation delete actions
    const deleteActions = [
        { label : 'Close', onClick : hideAlert , className : 'btn-secondary' },
        { label : 'Delete', onClick : deleteDesignation , className : 'btn-danger' }
   ]
    return(
        <Layout>
            { (roleName === "admin" || roleName === "branchLead") && <PageHeader title = 'Manage Designation' label = 'Add Designation' onClick = {() => showModal('addDesignation')}/> }
            { (roleName === 'employee' || roleName === "teamLead") && <PageHeader title = 'Manage Designation'/> }

            <Table isLoader={isLoader} header = { header } body = { designationList } paginationDetail={paginationDetail} routePage={onPaginationRoutePage} onChangeRowsPerPage={onChangeRowsPerPage} />

            {isShowModal && 
                <Modal title = {modalTitle} hideModal = {hideModal}>
                    <div className = {!status.showStatus ? 'd-block' : 'd-none'}>
                        <Form rows = {inputFields} className = 'col' value = {designation.newDesignation} errorMessage = {designation.errorMessage} onChange = {designationOnChange} hideModal = {hideModal}  />
                        <ModalFooter>
                            <Actions actions = {actions}/>
                        </ModalFooter>
                    </div>
                    <div className = {status.showStatus ? 'd-block mb-3' : 'd-none'}>
                        <Status spinner = {status.spinner} success = {status.success} successMessage = {status.successMessage} onClick = {hideModal} />
                    </div>
                </Modal>           
            }

            { isShowAlert && <Alert label = 'Are you sure delete this item' actions = { deleteActions } hide = { hideAlert } />}
        </Layout>
        
    )
}
export default Designations