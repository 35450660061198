import axios from "axios"
const BaseUrl = process.env.REACT_APP_BASEURL;

export const getUserDetails = (params) => {
   const url = BaseUrl+'users' 
   const token = localStorage.getItem ('token') || ''
   return axios ( {
       method : 'get',
       url,
       headers : {
           Authorization : 'Bearer '+token 
       },
       params

   } )
}


export const updatePassword  = (data) => {
    const url = BaseUrl + 'auth/update-password';
    const token = localStorage.getItem ('token') || ''
    return axios({
        method: 'post',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        },
        data
    });
}


export const updateTimezone  = (data) => {
    const url = BaseUrl + 'auth/update-timezone';
    const token = localStorage.getItem ('token') || ''
    return axios({
        method: 'post',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        },
        data
    });
}


export const updateUsername  = (data) => {
    const url = BaseUrl + 'auth/update-username';
    const token = localStorage.getItem ('token') || ''
    return axios({
        method: 'post',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        },
        data
    });
}