const FormLabel = (props)=>{
    const { label, mandatory = true, className = '' } = props
    return(
        <>
        {label && 
           <label className = {'label mb-1' + className}>{label}{mandatory && <span className = 'text-danger mandatory-input'>*</span>}</label>     
        }
        </>
    )
}
export default FormLabel ;