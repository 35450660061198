//components
import Actions from './Actions';
import Button from './Button';

const Alert = (props) => {
    const {label, actions = []} = props;
    const hideHandler = ()=>{
        if (props.hide){
            props.hide();
        }
    }
    return (
        <div className = "modal d-block bd-example-modal-sm" >
            <div className = "modal-dialog modal-sm">
                <div className = "modal-content">
                    <div className = 'modal-header border-0'>
                        <Button className = 'btn-close ms-auto' onClick = {hideHandler} />
                    </div>
                    <div className = 'modal-body f-s16 d-flex pt-0 pb-0'>
                        <div className = 'col-3 text-center'>
                            <span className = 'alert-icon text-danger'><i className = 'bi bi-info-circle-fill'></i></span>
                        </div>
                        <div className = 'col'>
                            {label}
                        </div>
                       
                    </div>
                    <div className = 'modal-footer border-0'>
                        <Actions actions = {actions}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Alert