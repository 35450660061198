import {useRef} from 'react';
import Button from './Button'

const QRCode = require('qrcode.react');

const QRCodeGenerator = (props) => {
    //-----props values --------
    // download = true - download button
    // regenerate = true - generate button
    // downloadQRCode - action in at the time of download
    // regenarateQRCode - pass the value generate QR code
    // value - QRcode generate value

    const { fileName = '', size = 100, title='' } = props
    const canvas = useRef();
    const downloadQRCode = (e) => {
        let link = document.createElement('a') ;
        link.download = title+'_QR_code_'+new Date().getTime() + '.png' ;
        link.href = document.getElementsByTagName('canvas')[0].toDataURL() ;
        link.click() ;
        if(props.downloadQRCode){
            props.downloadQRCode(e);
        }
        
    }

    const regenerateQRCode = (e) => {
        if(props.regenerateQRCode){
            props.regenerateQRCode(e);
        } 
    }

    return (
    <>
    <div>
        <div style = {{width : size , height : size}}>
            <QRCode ref = {canvas} style = {{width : size, height : size}} value = { props.value || '' } size = {400} />
        </div>
        <div style = {{width : size}}>
            {props.regenerate && 
                <Button  onClick = {regenerateQRCode} className = 'btn-sm text-center btn-primary w-100 mt-1' title = 'regenerate QRCode'>
                    <i className = 'bi bi-arrow-clockwise'></i>Regenerate
                </Button>
            }  
            {props.download && 
                <Button onClick = {downloadQRCode} className = 'btn-sm text-center btn-primary w-100 mt-1' title = 'download QRCode'>
                    <i className = 'bi bi-download'></i>Download
                </Button>
            }
        </div>
    </div>


    </>
        )
}

export default QRCodeGenerator 