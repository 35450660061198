import axios from "axios"
const BaseUrl = process.env.REACT_APP_BASEURL;


export const getCheckInDetail = () => {
    const url = BaseUrl+'attendance/checkIndetail' 
    const token = localStorage.getItem ('token') || ''
    return axios ( {
        method : 'get',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        }
 
    } )
 }

export const updateCheckin = (data) => {
   const url = BaseUrl+'attendance' 
   const token = localStorage.getItem ('token') || ''
   return axios ( {
       method : 'post',
       url,
       headers : {
           Authorization : 'Bearer '+token 
       },
       data

   } )
}


export const getAttendanceByDates = (params) => {
    const url = BaseUrl+'attendance/byDates' 
    const token = localStorage.getItem ('token') || ''
    return axios ( {
        method : 'get',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        },
        params 
    } )
 }