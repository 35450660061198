import { useState, useEffect } from 'react'
// import {useRouter} from 'next/router'
import {deleteEmployee as deleteEmployeeService} from '../services/employee'
//components
import Layout from '../Components/Layout';
import PageHeader from '../Components/Common/PageHeader';
import Table from '../Components/Common/Table';
import Alert from '../Components/Common/Alert';
//services
import {getEmployeesList as getEmployeesListService} from '../services/employee';
import {useHistory} from 'react-router-dom';

const Employees = (props) => {
    const router = useHistory() ;
    // state for get team from server and send to table
    const [ employeesList, setEmployeesList ] = useState ([])
    // state for delete employee
    const [employeeDetail, setEmployeeDetail] = useState()

    const [paginationDetail, setPaginationDetail] = useState({itemsPerPage:10, page:1, total:10});

    const [isLoader, setLoader] = useState(false);

    const roleName = localStorage.getItem('role');

    //state for show and hide the employee
    const [isShowAlert, setIsShowAlert] = useState(false)
    useEffect(() => {
        setLoader(true);
        getEmployeesListService().then((results) => {
            const employeeList = [];
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            setLoader(false);
            result.map((data)=> {
                employeeList.push({...data, name : data.first_name+ ' ' + data.last_name});
            })
            setPaginationDetail({itemsPerPage, page, total});
            setEmployeesList(employeeList);
        }).catch( () => {
            setLoader(false);
        })
    },[true])

    const routeEditPage = (employeeInfo) => {
        router.push('/EditEmployee/'+employeeInfo.id)
    }

    const routeDetailsPage = (employeeInfo) => {
        //console.log(employeeDetails)
        router.push('/EmployeeDetails/'+employeeInfo.id)
    }

    const showAlert = (employeeInfo)=> {
        setEmployeeDetail(employeeInfo)
        console.log(employeeInfo)
        setIsShowAlert(true)
    }

    const onPaginationRoutePage = (page) => {
        const updatePagination = { ...paginationDetail, page};
        setPaginationDetail(updatePagination);
        getEmployeesListService(updatePagination).then((results) => {
            const employeeList = [];
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            result.map((data)=> {
                employeeList.push({...data, name : data.first_name+ ' ' + data.last_name});
            })
            setEmployeesList(employeeList);
        }).catch()
    }

    const onChangeRowsPerPage = (itemsPerPage) => {
        const updatePagination = { ...paginationDetail, itemsPerPage, page:1};
        setPaginationDetail(updatePagination);
        getEmployeesListService(updatePagination).then((results) => {
            const employeeList = [];
            const { result=[], itemsPerPage=10, page=1, total=10} = results.data;
            result.map((data)=> {
                employeeList.push({...data, name : data.first_name+ ' ' + data.last_name});
            })
            setEmployeesList(employeeList);
        }).catch()
    }

    const hideAlert = () => {
        setIsShowAlert(false)
    }

    const deleteEmployee = ()=> {

        deleteEmployeeService(employeeDetail.id).then(()=>{
            const employeeList = [...employeesList];
            const employeeIndex = employeeList.findIndex((list)=> list.id == employeeDetail.id
            )
            employeeList.splice(employeeIndex, 1)
            setEmployeesList([...employeeList]);
            hideAlert();
        })

    }


    const actionsBtn = {
        label : 'Actions', key : 'action',
        actions : [
            { 
                className : 'btn-outline-success', 
                label : <i className = "bi bi-card-text"></i>,
                title : 'View Employee Details',
                onClick : (employeeInfo) => routeDetailsPage(employeeInfo)
            }
        ]
    };
    
    const editAction = {
        label : <i className = "bi bi-pencil-fill "></i>,
        key : "edit",
        className : 'btn-outline-primary ml-10',
        title : 'Edit Employee Details',
        onClick : (employeeInfo) => routeEditPage(employeeInfo)
    };

    const deleteAction = {
        label : <i className = "bi bi-trash-fill"></i>,
        key : "delete",
        className : 'btn-outline-danger ml-10',
        title : 'Delete Employee',
        onClick : (employeeInfo) => showAlert ( employeeInfo )
    }

    if(roleName === 'admin' || roleName === 'branchLead') actionsBtn.actions.push(editAction);

    if(roleName === 'admin') actionsBtn.actions.push(deleteAction);

    const header = [
        { key : 'id', label : 'Id'},
        { key : 'name', label : 'Name'},
        { key : 'designation_name', label : 'Designation'},
        { key : 'mail_id', label : 'Email'}
    ];

    if(roleName === 'admin' || roleName === 'branchLead' || roleName === 'teamLead') header.push(actionsBtn);

    const addEmployeePage = () => {
        router.push('/AddEmployee')
    }
    const deleteActions = [{label : 'Close', className : 'btn-secondary', onClick : hideAlert}, {label : 'Delete', className : 'btn-danger', onClick : deleteEmployee}]
    return (
        <Layout>
             { (roleName === "admin" || roleName === "branchLead") && <PageHeader title = 'Manage Employee' label = 'Add Employee' icon = 'bi bi-person-plus-fill' onClick = {addEmployeePage}  /> }
             { (roleName === 'employee' || roleName === "teamLead") && <PageHeader title = 'Manage Employee'  /> }
            <Table isLoader={isLoader} header = {header} body = {employeesList} paginationDetail={paginationDetail} routePage={onPaginationRoutePage} onChangeRowsPerPage={onChangeRowsPerPage} />
            {isShowAlert && 
                <Alert label = 'Are you sure delete this employee ?' actions = {deleteActions} hide = {hideAlert}/>            
            }
        </Layout>
    )
}
export default Employees