import axios from "axios"
const BaseUrl = process.env.REACT_APP_BASEURL;
export const getDesignationList = (params) => {
   const url = BaseUrl+'designations' 
   const token = localStorage.getItem ('token') || ''
   return axios ( {
       method : 'get',
       url,
       headers : {
           Authorization : 'Bearer '+token 
       },
       params

   } )
}

export const createDesignation = (data) => {
    const url = BaseUrl+'designations' 
    const token = localStorage.getItem ('token') || ''
    console.log(data)
    return axios( {
        method : 'post',
        url,
        headers : {
            Authorization : 'Bearer '+token
        },
        data
    })
}
export const updateDesignation = (data) => {
    const url = BaseUrl+'designations/' + data.id
    const token = localStorage.getItem ('token') || ''
    return axios( {
        method : 'put',
        url,
        headers : {
            Authorization : 'Bearer '+token
        },
        data
    })
}

export const deleteDesignation = (id) => {
    const url = BaseUrl+'designations/' + id ;
    const token = localStorage.getItem ('token') || '' ;
    return axios( {
        method : 'delete',
        url,
        headers : {
            Authorization : 'Bearer '+token
        }
    })
}