import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Button from './Common/Button';


const ExcelSheet = ({excelData, fileName, exportKeys}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const newExcel = excelData.map( (data) => {
        const newData = {};
        exportKeys.forEach(element => {
            newData[element.label || element.key] =  data[element.key];
        });
        return newData;
    })

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(newExcel);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }


    return (
        <>
            <Button className="btn btn-success btn-sm" onClick={exportToExcel}>Export to Excel</Button>
        </>
    )
}

export default ExcelSheet;