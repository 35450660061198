import { useState } from 'react';
// components
import Button from './Button';
import SearchBar from './SearchBar';
const Header=(props)=>{  
    const [isShowSearchBar, setIsShowSearchBar] = useState(false);

    const showSearchBar = ()=>{
        setIsShowSearchBar(true);
    };
    const hideSearchBar = ()=>{
        setIsShowSearchBar(false);
    }
    const roleName = localStorage.getItem("role");
    return(
    <header className = 'header d-flex align-items-center justify-content-between border-bottom'>
            <div  className="logo-container ps-md-4 ps-3 h-100 d-flex align-items-center">
                <Button className = "d-none d-lg-block text-light screen-control-btn" onClick = {props.changeScreenSize}>
                    <i className="bi bi-list"></i>
                </Button>
                <Button className = "text-light screen-control-btn d-lg-none" onClick = {props.showNavBar}>
                    <i className="bi bi-list"></i>
                </Button>
                <div className="logo text-light d-none d-md-block"><span>TheCheckIn.io</span></div>
            </div>
            <div className = "search-bar d-none d-md-block">
                {/* <Button className ='btn-secondary text-light'>{roleName}</Button> */}
                {/* <SearchBar options = {[{name:"siva",value:1}, {name:"Jaya",id:2}, {name:"siva1", id:3} ,{name:"ganesh", id:4} ,{name:"senthil", id:5}]}/> */}
            </div>
            {/* <div>
                <Button className = 'text-light d-md-none' onClick = {showSearchBar}>
                    <i className="bi bi-search"></i>
                </Button>
                <Button className = 'text-light'>
                    <i className="bi bi-bell-fill"></i>
                </Button>
            </div>
            <div className = { isShowSearchBar ? 'bg-light position-fixed top-0 start-0 end-0 bottom-0' : 'd-none'}>
                <div className = 'px-2 pt-2 d-flex'>
                    <div>
                        <Button onClick = {hideSearchBar}><i className="bi bi-x-lg"></i></Button>
                    </div>
                    <div className = 'col'>
                        <SearchBar options = {[{name:"siva",value:1}, {name:"Jaya",id:2}, {name:"siva1", id:3} ,{name:"ganesh", id:4} ,{name:"senthil", id:5}]}/>
                    </div>
                </div>
            </div> */}

            {/* <div className="pr-20 h-100 d-flex align-items-center">
                <span className="btn-group">
                    <Dropdown className="position-relative f-s16 text-primary" label={<><i className="bi bi-bell-fill"></i><span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">+99<span className="visually-hidden">unread messages</span></span></>} onClick="onClick">
                            <div className="dropdown-menu show top-100 end-0 f-s13">
                                <div className="border-bottom px-3 f-s16 pb-1">Notfication</div>
                                <div className="px-3 pt-1">
                                    you have no Notfication
                                </div>
                            </div>
                    </Dropdown>
                </span>            
                <span className=" ml-20 btn-group">
                <Dropdown label={<span><i className="bi bi-person-circle"></i></span>} onClick="onClick" className="f-s16">
                        <div className={" dropdown-menu show px-3 f-s13 text-center top-100 end-0 text-dark "}>
                            <div className="">
                                <Image
                                    src="/img-1.jpg"
                                    alt="Picture of the author"
                                    width={100}
                                    height={100}
                                    className="rounded-circle"
                                />
                            </div>
                            <div className="f-w-bold">Jagathesh</div>
                            <div className="f-s13 text-muted">CKN1615441830</div>
                            <div className="text-muted f-s13">jagathesd@gmail.com</div>
                            <Button  className ="btn-primary mt-2">Sign out</Button>
                        </div>
                </Dropdown>
                </span>
            </div>       */}
    </header>

    )
}
export default Header