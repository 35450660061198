//https://react-select.com/home
//npm i --save react-select

//demo options
    // const options = [
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' },
    // ];
import Select from 'react-select';
import SelectCreatable from 'react-select/creatable';
import FormLabel from './FormLabel';
const AutoComplete = (props)=>{
    const components = {
        DropdownIndicator: null,
    };
    const onSelect = (value, action)=>{
        if(props.onSelect){
            props.onSelect(value, action);
        }
    };
    const onChange = (value, action)=>{
        if(props.onChange){
            props.onChange(value, action)
        }
    }
    const {options = [], value , isClearable = true, mandatory = true, placeholder = 'Select', maxMenuHeight = 250, 
        minMenuHeight = 300, 
        dropDownIndicator = true, 
        isLoading=false,
        isMultiple=false,
        isAllowCreate=false
    } = props;

    const newOptions =  options.map((item)=>{
        return {...item, value : item.id, label : item.name};
    });

    const handleCreate = (inputValue) => { 
        if(props.handleCreate) {
            props.handleCreate(inputValue);
        }
    }

    let newValue = [];
    if(typeof value == 'object' && value.name && value.id){
        newValue = [{...value, value : value.id, label : value.name}];
    }
    return(
        <>
            {props.label && <FormLabel label = {props.label} mandatory = {mandatory}/>}
            {!props.handleCreate && <Select 
                components = { !dropDownIndicator && components}
                backspaceRemovesValue = {true}
                isClearable = {isClearable}
                // isLoading = {true}
                options = {newOptions}
                isMulti={isMultiple}
                value = {value}
                onChange = {onSelect}
                onInputChange = {onChange}
                placeholder = {'Search'} 
                isSearchable = {true}
                maxMenuHeight = {maxMenuHeight}
                classNamePrefix = 'form-control'
                minMenuHeight = {minMenuHeight}
            />}
            {isAllowCreate && <SelectCreatable
                isClearable = {isClearable}
                isDisabled={isLoading}
                isLoading={isLoading}
                onChange={onSelect}
                isMulti={isMultiple}
                onCreateOption={handleCreate}
                options={newOptions}
                value={value}
            />}
            
        </>

    )
}
export default AutoComplete;