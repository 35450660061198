import PageHeader from '../Components/Common/PageHeader'
import Layout from '../Components/Layout'
const DashBoard = (props) => {
    return (
        <>
            <Layout>
               <PageHeader title="Dashboard"></PageHeader>
               <div className=''>
                    <div className='col-3'>                        
                        <div className="card mt-30 ml-20 to-do-wrapper">
                                <div className="card-body"> 
                                    <h5 className="card-title">Welcome Senthil,</h5>
                                    <p>You have created account successfully!</p>                     
                                    <ul className="list-group">
                                        <li className="list-group-item"><i className="bi bi-check-circle-fill text-success me-2"></i>Created new account</li>
                                        <li className="list-group-item"><i className="bi bi-check-circle-fill text-success me-2"></i>Add new team</li>
                                        <li className="list-group-item"><i className="bi bi-check-circle-fill text-success me-2"></i>Add new branch</li>
                                        <li className="list-group-item"><i className="bi bi-circle me-2"></i>Add new shift</li>
                                        <li className="list-group-item"><i className="bi bi-circle me-2"></i>Add new employee</li>
                                    </ul>
                                </div>
                        </div>
                    </div>
                    <div className='col'></div>
               </div>
                
            </Layout>
        </>
    )
}

export default DashBoard