import React, {useState, useRef, useContext} from 'react';
import Header from './Common/Header.js';
import SideBar from './Common/SideBar';
import Button from './Common/Button.js';
import {
    BrowserRouter as Router,
    useHistory
  } from "react-router-dom";
import { adminNavItems } from '../constant/navItems' ;
import { getMenus } from '../services/menu.js';
import { logout } from '../Utils/login';
import { menuItemsContext } from "../context"

const Layout = (props) =>{
    const history = useHistory();
    const overlay = useRef(null);
    const navBar = useRef(null);
    const [isLargeScreen, setIsLargeScreen] = useState(false);
    const [isShowNavBar, setIsShowNavBar] = useState(false);
    const menuList = useContext(menuItemsContext);
    


    //for desktop view
    const changeScreenSize=()=>{
        setIsLargeScreen(!isLargeScreen);
        console.log('click large')
    };
    //for mobile view
    const showNavBar = ()=>{
        setIsShowNavBar(!isShowNavBar);
    };


   

    const hideNavBar = (e)=>{
        // console.log(e.target.contains(navBar.current))
        // if(!e.target.contains(navBar.current)){
        //     setIsShowNavBar(false);
        // }
        // setIsShowNavBar(false);
        
        if(e.target == overlay.current){
            setIsShowNavBar(false);
        }
    }
    
    const navBarClass = isShowNavBar? 'd-block ' : 'd-none';
    const className = isLargeScreen ? "main-content-large-screen" : "main-content-normal-screen" ;
    const navBarSize = isLargeScreen ? "nav-large-screen" : "nav-normal-screen";
    return(
        <>
        <div>
            <Header changeScreenSize = {changeScreenSize} showNavBar = {showNavBar}/>
            <div className = {'bg-white border-right overflow-auto nav-bar nav-bar-md d-none d-md-block '+ navBarSize}>
                <SideBar menuList={menuList || []} />
            </div>  
            <div className = {'nav-bar-overlay d-lg-none nav-bar ' + navBarClass} ref = {overlay} onClick = {hideNavBar}>
                <div className = 'bg-white overflow-auto nav-normal-screen-sm h-100' ref = {navBar}>
                    <div className = 'ps-md-4 ps-3 bg-primary nav-bar-header d-flex align-items-center'>
                        <Button className = "text-light screen-control-btn" onClick = {showNavBar}>
                            <i className="bi bi-list"></i>
                        </Button>
                        <div className = 'text-light ps-2'>Checkin</div>
                    </div>
                    <SideBar menuList={menuList || []} />
                </div>
            </div>
            <div className="main-content">
                <div className = {className}>
                    <div className="bg-white rounded">
                        {props.children}
                    </div>
                </div>
            </div> 
        </div>
        </>
    )
}
export default Layout;