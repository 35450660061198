import { useState, useEffect } from "react"
//components
import Button from "./Button"
const Accordion = (props) => {
    const { className = '', title = 'Accordion Item', showBody = true } = props;
    const onClickHandler = (e) => {
        if(props.onClick) props.onClick(e)
    }

    //change drop down icon style
    const dropDownIconClass = showBody ? '' : 'collapsed' ;
    // show and hide body
    const bodyShowClass = showBody ? 'show' : '' ;

    return(
        <div className = 'accordion'>
            <div className = {'accordion-item '+className} >
                <h2 className = 'accordion-header'>
                    <Button className = {'accordion-button ' + dropDownIconClass} onClick = {onClickHandler}>
                        {title}
                    </Button>
                </h2>
                <div className = {'accordion-collapse collapse ' + bodyShowClass}>
                    <div className = "accordion-body">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Accordion